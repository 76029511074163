import React, { useEffect, useRef, useState } from "react";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import { Fade, Input, Zoom } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import { commonErrorToastMessage } from "../toastify/AllToastifyErrors";
const TitleAndDomainInput = ({
  title,
  setTitle,
  titleInputRef,
  domain,
  handleDropdownChange,
  domainSelectRef,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [customValue, setCustomValue] = useState("");

  const otherButtonRef = useRef(null);

  const isYoutubeModalOpen = localStorage.getItem("isYoutubeModalOpen");

  // Title name change
  const handleTitleChange = (e) => {
    const { value } = e.target;

    // if (value.length > 50) {
    //   // Log error message to console when user enters more than 50 characters
    //   console.error("Title should be 50 chars.");
    //   commonErrorToastMessage("Title name must be under 50 characters");
    // }

    setTitle(value);
  };

  // "Other" menu visibility
  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
    setCustomValue(""); // Clear the custom value
    setInputError(false); // Also clear any input errors if present
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (isSubMenuOpen) {
      // Close the submenu if the main dropdown is being toggled
      setIsSubMenuOpen(false);
      setCustomValue(""); // Clear the custom value
      setInputError(false); // Also clear any input errors if present
    }
  };

  const handleOptionSelect = (value, e) => {
    // e.stopPropagation(); // Prevent any parent handlers from being notified of the event
    const event = { target: { value } };
    handleDropdownChange(event);
    setIsDropdownOpen(false);
  };

  // Function to handle click outside of menu
  const handleClickOutside = (event) => {
    // Assuming the dropdown menu has a class "dropdown-menu", you can use a query selector to get it.
    const dropdownMenu = document.querySelector(".dropdown-menu");

    if (
      domainSelectRef.current &&
      !domainSelectRef.current.contains(event.target) &&
      otherButtonRef.current &&
      !otherButtonRef.current.contains(event.target) &&
      dropdownMenu &&
      !dropdownMenu.contains(event.target)
    ) {
      setIsDropdownOpen(false);
      setIsSubMenuOpen(false);
      setCustomValue(""); // Clear the custom value
      setInputError(false); // Also clear any input errors if present
    }
  };

  // Effect to add/remove event listener
  useEffect(() => {
    // If the dropdown is open, listen for clicks outside
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, isSubMenuOpen]);

  const handleCustomValueChange = (e) => {
    const { value } = e.target;

    if (value.length > 20) {
      setInputError(true); // Show the error
      commonErrorToastMessage("Domain name must be under 20 characters");
      // Don't update the customValue state to prevent more characters
    } else {
      if (inputError) setInputError(false); // Hide the error message if previously shown
      setCustomValue(value);
    }
  };

  const handleCustomValueSubmit = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleDropdownChange({ target: { value: customValue } });
      setIsDropdownOpen(false);

      // setSelectOpen(false); // Close the Select dropdown
      // handleSubMenuClose();
    }
  };

  return (
    <div className="flex place-content-between align-middle">
      {/* Title input */}
      <Tooltip
        title={title}
        arrow
        placement="bottom"
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 300 }}
      >
        <div className="flex border-b-2">
          <span className="text-[#cc0000] text-3xl font-bold me-2">*</span>
          <input
            type="text"
            className="font-semibold outline-none title-input w-[24vw]"
            id="titleText"
            placeholder="Click to add a title"
            value={title}
            ref={titleInputRef}
            onChange={handleTitleChange} // Updated to use the new handler
            maxLength={50}
          />
        </div>
      </Tooltip>

      {/* Dropdown for domain selection */}
      {
      //   <div className="relative border-b-2 pb-2 mx-4 flex align-middle justify-center">
      //   <span className="text-[#cc0000] text-3xl font-bold me-2  mt-[-5px]">
      //     *
      //   </span>
      //   <div className=" flex justify-center items-center w-full">
      //     <div className="relative text-[#f4833d] cursor-pointer text-center rounded border border-[#f4833d]">
      //       <Tooltip
      //         title={domain}
      //         arrow
      //         placement="bottom"
      //         TransitionComponent={Zoom}
      //         TransitionProps={{ timeout: 300 }}
      //       >
      //         <div
      //           className="dropdown-button title-input w-[18vw] py-2 flex align-middle place-content-center justify-center  items-center hover:bg-[#929292] hover:text-white rounded"
      //           onClick={handleDropdownToggle}
      //           ref={domainSelectRef}
      //         >
      //           <span>{domain || "Select Domain"}</span>{" "}
      //           <span>
      //             <i className="fa-solid fa-caret-down mx-4"></i>
      //           </span>
      //         </div>
      //       </Tooltip>

      //       {/* Dropdown Menu Items */}
      //       {isDropdownOpen && (
      //         <div className="dropdown-menu absolute left-1/2 transform -translate-x-1/2 mt-1 bg-white z-10 rounded">
      //           <div
      //             className="dropdown-item-domain hover:rounded-xl "
      //             onClick={() => handleOptionSelect("History")}
      //           >
      //             History
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Geography")}
      //           >
      //             Geography
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Natural Science")}
      //           >
      //             Natural Science
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Technology")}
      //           >
      //             Technology
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Chemistry")}
      //           >
      //             Chemistry
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Biology")}
      //           >
      //             Biology
      //           </div>

      //           <div
      //             className="dropdown-item-domain hover:rounded-xl"
      //             onClick={() => handleOptionSelect("Earth Science")}
      //           >
      //             Earth Science
      //           </div>

      //           {/* ... Other menu items ... */}
      //           <div
      //             className="dropdown-item-domain   hover:rounded-xl other-button"
      //             onMouseEnter={handleSubMenuToggle}
      //             onMouseLeave={handleSubMenuToggle}
      //             ref={otherButtonRef}
      //           >
      //             <span> Other</span>{" "}
      //             <span>
      //               <i className="fa-solid fa-caret-right"></i>
      //             </span>
      //             {/* Submenu */}
      //             {isSubMenuOpen && (
      //               <div className="submenu w-full rounded">
      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("Management")}
      //                 >
      //                   Management
      //                 </div>

      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("Social Sciences")}
      //                 >
      //                   Social Sciences
      //                 </div>

      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("Human Resources")}
      //                 >
      //                   Human Resources
      //                 </div>

      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("Humanities")}
      //                 >
      //                   Humanities
      //                 </div>

      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("Marketing")}
      //                 >
      //                   Marketing
      //                 </div>

      //                 <div
      //                   className="submenu-item hover:rounded-xl"
      //                   onClick={() => handleOptionSelect("News and Events")}
      //                 >
      //                   News and Events
      //                 </div>

      //                 <div className="submenu-item hover:rounded-lg">
      //                   <input
      //                     value={customValue}
      //                     className="border border-[#f88742] rounded-lg p-1 outline-none w-full text-black"
      //                     onChange={handleCustomValueChange}
      //                     onKeyDown={handleCustomValueSubmit}
      //                     placeholder="Enter your domain"
      //                   />
      //                 </div>
      //               </div>
      //             )}
      //           </div>
      //         </div>
      //       )}
      //     </div>
      //   </div>
      // </div>
    }
    </div>
  );
};

export default TitleAndDomainInput;
