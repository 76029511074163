import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import quillionzLogoImage from "../../../assets/images/quillionz_app_logo.png";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import {
  handleNewPasswordValidationAndSetErrors,
  handlePasswordValidationAndSetErrors,
} from "../../utils/Errors/Login/passwordValidationError";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
  emptyChangePassword,
  incorrectOldPassword,
  passwordChangedSuccess,
  resetPasswordSuccess,
} from "../../components/toastify/AllToastifyErrors";
import { changePassword } from "../../api/changePassword";

import { resetUserPassword } from "../../api/resetPassword";
import {encrypt } from 'n-krypta';
const ResetPassword = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [isChangePassword, setIsChangePassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [oldPasswordMismatch, setOldPasswordMismatch] = useState(false);

  // Function to handle new password input change
  const handleNewPasswordChange = (e) => {
    const { value } = e.target;
    setUserData({ ...userData, newPassword: value });

    // Validate Password
    handleNewPasswordValidationAndSetErrors(value, setErrors);

    // Check if the new password and confirm password match
    // setPasswordsMatch(value === userData.confirmPassword);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // change password API calling
  const handleResetPassword = async () => {
    setIsChangePassword(true);

    if (!userData.newPassword || !userData.confirmPassword) {
      commonErrorToastMessage("Please enter your password.");
      setIsChangePassword(false);
      return;
    }

    try {
      const newPassword = userData.newPassword;
      const confirmPassword = userData.confirmPassword;
      const email = String(queryParams.get("email"));
      const token = String(queryParams.get("token"));

      const secretemail = "vnrvkwvwrjvnwl";
      const emailencrypt = encrypt(email ,secretemail);
      console.log("emailencrypt :",emailencrypt);
      console.log("Token:", token);
      const response = await resetUserPassword(
        newPassword,
        confirmPassword,
        emailencrypt,
        token
      );

      console.log("Reset Password Response : ", response);

      if (errors.newPassword) {
        commonErrorToastMessage("Please enter a strong password.");
        setIsChangePassword(false);

        return;
      }

      // if (newPassword !== confirmPassword) {
      //   commonErrorToastMessage(
      //     "New password cannot be the same as the old password."
      //   );
      //   setIsChangePassword(false);
      //   return;
      // }

      // if (!passwordsMatch) {
      //   commonErrorToastMessage(
      //     "New Password and Confirm Password does not match.."
      //   );
      //   return;
      // }

      // Password changed successfully
      commonSuccessToastMessage(
        "Success! Your password has been reset successfully."
      );
      setIsChangePassword(true);
      navigate("/");

      // if (response.status === 200) {
      //   // Password changed successfully
      //   commonSuccessToastMessage(
      //     "Success! Your password has been reset successfully."
      //   );
      //   setIsChangePassword(true);
      //   navigate("/");
      // } else {
      //   console.error("API Error:", response.error);
      //   setErrorMessage("Failed to change password. Please try again.");
      // }

      setIsChangePassword(true);
    } catch (error) {
      if (userData.newPassword !== userData.confirmPassword) {
        commonErrorToastMessage(
          "New password cannot be the same as the old password."
        );
        setIsChangePassword(false);
        return;
      }
      console.error("Error in Reset Password :", error);
      setIsChangePassword(true);
    }
  };

  const handleCancelChangePassword = () => {
    navigate("/");
  };

  return (
    <div className="">
      <div className="bg-[#35404b] px-10 py-3 flex justify-between items-center md:flex sm:grid">
        <div className="flex items-center ">
          {/* Quillionz Logo */}
          <img src={quillionzLogoImage} alt="Logo" className="w-[88%]" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-[81vh] change-password">
        <div className=" py-5 text-center">
          <h1 className="text-[#212529] text-3xl font-medium">
            Reset Password
          </h1>
        </div>

        <div className="w-1/3 mx-auto">
          <div className="mb-3 flex font-medium">
            <p className="">Email :</p>
            <p className="px-2 ">{String(queryParams.get("email"))}</p>
          </div>

          {/* New Password Input */}
          <p className="mb-3">
            <input
              type="password"
              className="w-full border border-[#ced4da] p-2 rounded"
              placeholder="New Password "
              name="newPassword" // Add this attribute
              value={userData?.newPassword}
              onChange={handleNewPasswordChange}
            />
            {errors.newPassword && (
              <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message text-start">
                {errors.newPassword}
              </span>
            )}
          </p>

          {/* Confirm Password Input */}
          <p className="mb-3">
            <input
              type="password"
              className="w-full border border-[#ced4da] p-2 rounded"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={userData.confirmPassword}
              onChange={(e) => {
                const { value } = e.target;
                setUserData({ ...userData, confirmPassword: value });
                setPasswordsMatch(userData.newPassword === value);
              }}
            />
            {!passwordsMatch && (
              <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
                New Password and Confirm Password does not match.
              </span>
            )}
          </p>
        </div>

        {/* Change Password Button */}
        <div className="flex gap-4 place-content-center align-middle">
          <Tooltip
            title="Change your password"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              onClick={handleResetPassword}
              className={`rounded mt-2 py-2 hover:opacity-90 bg-[#f4833d] border-[#f4833d] text-white   `}
            >
              <span className=" font-semibold mx-8">Change Password</span>
            </button>
          </Tooltip>

          {/* Cancel Button */}
          <Tooltip
            title="Cancel"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              onClick={handleCancelChangePassword}
              className={`rounded mt-2 py-2 hover:opacity-90 text-[#f4833d] hover:bg-[#6c757d] hover:border-[#6c757d] hover:text-white border border-[#f4833d]  `}
            >
              <span className=" font-semibold mx-8">Cancel</span>
            </button>
          </Tooltip>
        </div>
      </div>

      <MainFooter />
    </div>
  );
};

export default ResetPassword;
