import React, { useState } from "react";
import smileEmoji from "../../../assets/images/smileIcon.png";
import neutralEmoji from "../../../assets/images/neutralIcon.png";
import sadEmoji from "../../../assets/images/sadIcon.png";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const HelpingPointer = ({
  isSampleButtonClicked,
  content,
  handleTextAreaChange,
  handleUploadButtonClick,
  handleFileInputChange,
  handleYoutubeModalOpen,
  isFileUploaded,
  textareaRef,
  fileInputRef,
}) => {
  const [showTooltip, setShowTooltip] = useState(true);

  const isCanvas = useCanvasDetection();

  const handleUploadLinkClick = () => {
    handleUploadButtonClick();
    setShowTooltip(true); // Hide the tooltip when the link is clicked
  };

  return (
    <div
      className={`text-justify  ${
        isCanvas
          ? "md:mt-[-44%] lg:mt-[-38%] "
          : "md:mt-[-40%] sm:mt-[-80%] lg:mt-[-30%]"
      } ${isSampleButtonClicked ? "" : ""}`}
      value={content}
      onChange={handleTextAreaChange}
      // Disable the textarea if a valid file is uploaded
      disabled={isFileUploaded}
    >
      <div className="text-center  text-[#777e8d] ">
        {/* <input
          ref={textareaRef}
          className={`  w-full py-2 text-xl bg-transparent border-none outline-none custom-scrollbar ${
            isSampleButtonClicked ? "" : ""
          } input-box input-text-color`}
          value={content}
          onChange={handleTextAreaChange}
          // Disable the input if a valid file is uploaded
          disabled={isFileUploaded}
          placeholder="Hi there, just type or paste something here to get started."
        /> */}
        {/* Title line of Helper Block */}
        <p className="text-[2rem] ">
          Hi there, just type or paste something here to get started.
        </p>{" "}
        <p className="text-2xl my-2">
          We’ve got a few pointers to help you along.
        </p>
      </div>

      <div className="flex gap-10 place-content-center py-4 text-[#777e8d]">
        {/* First Paragraph of Helper Block */}
        <div className=" w-1/3 ">
          <p className=" mb-4">
            <img className="block mx-auto opacity-40" src={smileEmoji} alt="" />
          </p>
          <p>
            Planning to create a test on WWII for your history class? Want a
            quiz on an encyclopedia article? Need questions on a product
            brochure?
          </p>
          <p className="mt-4">
            That’s perfect. Quillionz loves this sort of content! Go right
            ahead!
          </p>
        </div>

        {/* Second Paragraph of Helper Block */}
        <div className="w-1/3  ">
          <p className="mb-4">
            <img
              className="block mx-auto opacity-40"
              src={neutralEmoji}
              alt=""
            />
          </p>
          <p className="">
            Want to experiment with your class notes? An article from your
            favorite travel blog? Or{" "}
            {showTooltip ? (
              <Tooltip
                title="File size should be less than 10MB."
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <span
                  className="cursor-pointer hover:underline text-[#f4833d]"
                  onClick={handleUploadLinkClick}
                >
                  Upload a PDF/docx?
                </span>
              </Tooltip>
            ) : (
              <span
                className="cursor-pointer hover:underline text-[#f4833d]"
                onClick={handleUploadLinkClick}
              >
                Upload a PDF/docx?
              </span>
            )}
            <span>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
            </span>
            {/* TODO : The 'Upload YouTube' feature will be implemented at a later stage, after the initial launch. For now, this aspect is being set aside. */}
            {
            // <span className="">or</span>{" "}
            //   <span
            //   className=" cursor-pointer text-justify hover:underline text-[#f4833d] "
            //   onClick={handleYoutubeModalOpen}
            // >
            //   Enter a YouTube link with closed captions.
            // </span>
          }
          </p>
          <p className="mt-4">
            Sure, give it a shot. Just be patient, it may take some work to get
            it right.
          </p>
        </div>

        {/* Third Paragraph of Helper Block */}
        <div className=" w-1/3 ">
          <p className="text-center mb-4">
            <img className="block mx-auto opacity-40" src={sadEmoji} alt="" />
          </p>
          <p>
            Want to try a poem? A sonnet? A cool YouTube video or a Harry
            Potter? Umm, not the best idea, unless you’re feeling brave…
          </p>
          <p className="mt-4">
            Sure, give it a shot. Just be patient, it may take some work to get
            it right.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpingPointer;
