// Importing necessary modules and components
import React, { useEffect, useState, useRef } from "react";
import quillionzLogoImage from "../../../assets/images/quillionz_app_logo.png";
import quillionzLoginLogoImage from "../../../assets/images/quillionzLoginLogo.png";
import loginHowItWorks1Image from "../../../assets/images/LoginPage/loginHowItWorks1.png";
import loginHowItWorks2Image from "../../../assets/images/LoginPage/loginHowItWorks2.png";
import loginCopyPasteImage from "../../../assets/images/LoginPage/loginCopyPaste.png";
import loginChooseKeywordsImage from "../../../assets/images/LoginPage/loginChooseKeywords.png";
import loginReviewContentImage from "../../../assets/images/LoginPage/loginReviewContent.png";
import loginGetQuestionsImage from "../../../assets/images/LoginPage/loginGetQuestions.png";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce"
import { Link, useNavigate, useLocation, redirect } from "react-router-dom";
import { userLogin } from "../../api/userLogin";
import useAuth from "../../hooks/useAuth";
import { format } from 'date-fns';
import ReCAPTCHA from "react-google-recaptcha";

import { handleEmailValidationAndSetErrors } from "../../utils/Errors/Login/emailValidationError";
import { handlePasswordValidationAndSetErrors } from "../../utils/Errors/Login/passwordValidationError";
import {
  accountLockedToast,
  commonErrorToastMessage,
  emptyEmailAndPassword,
} from "../../components/toastify/AllToastifyErrors";
import { Tooltip, Zoom } from "@mui/material";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import { fetchUsersAllQuestions } from "../../api/fetchUsersAllQuestions";
import { logout } from "../../api/logout";
import {Privacy_Policy} from "../../api/PrivacyPolicys";

// Styled component for the root container
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const SITE_KEY ='6LfB128pAAAAACbcuiJudHbXr7mbveD8tTRIKQyM';
// LoginPage component
const LoginPage = () => {

  const [recaptchaValue , setRecaptchaValue] = useState('');

  const captchRef = useRef();
  // State for controlling forget password flow
  let [forgetPassword, setForgetPassword] = useState(false);

  // State for displaying error messages
  const [errorMessage, setErrorMessage] = useState("");

  // Loading overlay on Login click
  const [isLoading, setIsLoading] = useState(false);

  // Custom hook for authentication
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  // State for managing form input errors
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  // State for remembering user's choice
  const [rememberMe, setRememberMe] = useState(false);

  // State variable to keep track of whether the agree checkbox is checked or not.
  const [agreeChecked, setAgreeChecked] = useState(false);

  // State for user data (email and password)
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);

  let [showprivacyPolicys, setShowprivacyPolicys] = useState("");

  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    if (showprivacyPolicys === null) {
      return setShowprivacyPolicys;
    }
  }, [showprivacyPolicys]);

  // console.log("showprivacyPolicys  :",showprivacyPolicys);
  useEffect(() => {
    if (query.length > 0) {
      if (!/^\s+$/.test(query) && query !== "") {
        const debouncedSearch = debounce(() => {
          handleEmailChange(query);
        }, 500);

        debouncedSearch();
        return () => {
          debouncedSearch.cancel();
        };
      }
    } else {
      setData([]);
    }
  }, [query]);

  // console.log('ShowprivacyPolicys 1 :', ShowprivacyPolicys);
  const handleEmailChange = debounce(async (value) => {
    // const { value } = e.target;

    // Validate Email and set errors
    handleEmailValidationAndSetErrors(value, setErrors);

    // Check if the email is valid using the regex pattern
    if (
      value &&
      /^[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})+$/.test(
        value
      )
    ) {
      // Make the Axios request
      try {
        const response = await Privacy_Policy(value);
        console.log("response  :", response);

        const result = response?.data?.privacyPolicy;

        setShowprivacyPolicys(result);
      } catch (error) {
        console.error("email doesn't exists:", error);
        // Handle error if needed
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: `email doesn't exists`,
        }));
      }
    }
  }, 300);

  // Function to handle password input change
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setUserData({ ...userData, password: value });

    // Validate Password
    handlePasswordValidationAndSetErrors(value, setErrors);
  };

  const hideLoadingOverlayAfterTimeout = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 2000 milliseconds = 2 seconds
  };

  // const token1 =
  //   "amoleyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjQzLCJpYXQiOjE2OTU2MzY4MTMsImV4cCI6MTY5NTY0MDQxM30.VwJljJcRfHQiQijlPv-cHc5BCtxDCZz3IhLqHRog9Iw";

  // localStorage.setItem("token1", token1);

  const [logoutDialog, setLogoutDialog] = useState(false);

  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    const tokenFromLocalStorage = localStorage.getItem("token");
    const tokenFromSessionStorage = sessionStorage.getItem("token");

    if (
      (tokenFromLocalStorage || tokenFromSessionStorage) &&
      localStorage.getItem("isLoggedIn")
    ) {
      // If token exists in either storage, navigate the user to the intended page
      navigate("/submitcontent");
    }
  }, [navigate]);

  useEffect(() => {
    const handleUserActivity = () => {
      clearTimeout(inactivityTimeoutRef.current);

      inactivityTimeoutRef.current = setTimeout(() => {
        // Auto-logout
        handleLogout();
      }, 60 * 60 * 1000);
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Perform any cleanup or logout actions here
      // You may want to return a string to show a confirmation dialog
      event.returnValue = undefined;
    };

    const handleUnload = () => {
      // Perform any cleanup or logout actions here
      handleLogout();
    };
    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    // Set the initial inactivity timer
    handleUserActivity();

    return () => {
      clearTimeout(inactivityTimeoutRef.current);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, ["mousemove", "keydown"]);

  const handleLogout = async () => {
    try {
      // Call the logout API
      const response = await logout(localStorage.getItem("email"));

      // Clear storage and navigate to the home page
      localStorage.clear();
      sessionStorage.removeItem("isLoggedIn");
      navigate("/");

      // Set logout dialog state or perform other actions if needed
      setLogoutDialog(true);
    } catch (error) {
      console.error("Error while logout:", error);

      // Set logout dialog state or handle errors if needed
      setLogoutDialog(true);
    }
  };

  // Function to handle changes in agree checkbox
  const handleAgreeCheckboxChange = () => {
    setAgreeChecked(!agreeChecked);

    if (!agreeChecked) {
      // Checkbox is checked, set the current date
      const currentDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      setPrivacyPolicy(currentDate);
    } else {
      // Checkbox is unchecked, clear the privacy policy
      setPrivacyPolicy(null);
    }
  };

  const navtosignupprice = () => {
    window.open(
      "https://www.quillionz.com/indexStaging.html#pricing",
      "_blank"
    );
  };

  // Function to handle login process
  const handleLogin = async () => {
    try {
      captchRef.current.reset()
      // Check if the agree checkbox is visible and not checked
      // TODO: We are using this terms and condition checkbox
      if (showprivacyPolicys === null && !agreeChecked) {
        setErrorMessage("Please agree to the terms before logging in.");
        return;
      }

      setIsLoading(true);
      // Validate Password
      if (userData.email === "" && userData.password === "") {
        // handleEmailValidationAndSetErrors("", setErrors);
        // handlePasswordValidationAndSetErrors("", setErrors);
        // emptyEmailAndPassword();
        commonErrorToastMessage("Please enter a email and password.");
        setIsLoading(false);
      }

      // TODO : When logging in, two APIs are invoked: 'userLogin' from the Node.js environment and 'fetchUsersAllQuestions' (Used for History Page) from the Python environment (managed by Vikas). Consequently, any modifications to the Python API or server downtime on the Python side hinder the login process.

      // Call user_login API for authentication (First API called while Login)
      const response = await userLogin(
        userData?.email,
        userData?.password,
        privacyPolicy,
        recaptchaValue
      );

      console.log("Login Response : ", response);

      const token = response?.data.token;
      const username = response?.data.username;
      const userId = response?.data.userId;
      const UserAttempts = response?.data.remainingQuestionSets;
      const UsergptAttempts = response?.data.Remainingquestionsetgpt;
      const isGptActive = response?.data.isGptActive;
      const UserLoggedIn = response?.data.currentDate;
      const userGPTKey = response?.data.Usergpt_key;
      const remainingDaysInMonth = response?.data.remainingDaysInMonth;
      // From plan subscription date to 30 days
      const monthEndDate = response?.data?.calculatedDate;
      const planExpiryDate = response?.data?.ExpiryDate;
      const Plan_Validity_Subscription =
        response?.data?.Plan_Validity_Subscription;
      const isPlanExpired = response?.data?.isPlanExpired;

      if (rememberMe) {
        localStorage.setItem("isLoggedIn", true);
      } else {
        sessionStorage.setItem("isLoggedIn", true);
      }
      let isLoggedIn = sessionStorage.getItem("isLoggedIn");

      localStorage.setItem("token", token);

      const getFormattedMonth = (dateString) => {
        const date = new Date(dateString);
        const options = { month: "short", year: "numeric", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
      };

      // Assuming the value of monthEndDate is in 'MM/DD/YYYY' format
      const subscriptionPlanExpiryDate = getFormattedMonth(planExpiryDate);

      // let setToken = isLoggedIn
      //   ? localStorage.setItem("token", token)
      //   : localStorage.setItem("token", token1);

      localStorage.setItem("email", userData.email);
      localStorage.setItem("username", username);
      localStorage.setItem("Id", userId);
      localStorage.setItem("UserAttempts", UserAttempts);
      localStorage.setItem("UsergptAttempts", UsergptAttempts);
      localStorage.setItem("isGptActive", isGptActive);
      localStorage.setItem("UserLoggedIn", UserLoggedIn);
      localStorage.setItem("userGPTKey", userGPTKey);
      localStorage.setItem("remainingDaysInMonth", remainingDaysInMonth);
      localStorage.setItem("monthEndDate", monthEndDate);
      localStorage.setItem("planExpiryDate", subscriptionPlanExpiryDate);
      localStorage.setItem("subscriptionPlan", Plan_Validity_Subscription);

      let isPlanExpiredvar = localStorage.setItem(
        "isPlanExpired",
        isPlanExpired
      );
      console.log("isPlanExpiredvar :", isPlanExpiredvar);

      sessionStorage.setItem("isPlanExpired", isPlanExpired);

      let email = userData?.email;
      let password = userData?.password;

      const accessToken = response?.data.token;

      setAuth({ email, password, accessToken });

      // Close the loading overlay
      // hideLoadingOverlayAfterTimeout();

      // Call the user history API (Python API) (Second API called while Login)
      const userQuestionsHistory = await fetchUsersAllQuestions(email);

      // localStorage.setItem("usersQuestionHistory", response.data.doc_ids);

      // console.log("Login Response : ", userQuestionsHistory);
      localStorage.setItem(
        "usersQuestionHistory",
        JSON.stringify(userQuestionsHistory.data.doc_list)
      );
      setIsLoading(false);

      // Redirect to the intended page after successful login
      navigate("/submitcontent");
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.error);
      // if (error?.response?.data?.error === "Plan Expired") {
      //   // commonErrorToastMessage(
      //   //   "Your plan has expired. To continue enjoying our services, please renew your plan."
      //   // );
      //   commonErrorToastMessage(
      //     "Your plan has expired. Please renew your plan to take the benefits of all the features.."
      //   );
      //   // hideLoadingOverlayAfterTimeout();
      //   return;
      // }
      // Close the loading overlay
      // hideLoadingOverlayAfterTimeout();

      console.log("Login Response : ", error);

      if (error.response?.status === 429) {
        setErrorMessage(error.response.data.error);
        // accountLockedToast(error.response.data.error);
        commonErrorToastMessage(error.response.data.error);
        console.error("Account Locked : ", error.response.data.error);
        // Close the loading overlay
        // hideLoadingOverlayAfterTimeout();
        setIsLoading(false);
      }

      // if (userData.email !== "" && userData.password !== "") {
      //   localStorage.removeItem("token"); // Remove the invalid token
      //   setErrorMessage("Incorrect email or password.");
      //   setIsLoading(false);

      //   // Close the loading overlay
      //   // hideLoadingOverlayAfterTimeout();
      //   // return;
      // }

      if (error.response?.status === 401) {
        setErrorMessage(error.response.data.error);
        // hideLoadingOverlayAfterTimeout();
        setIsLoading(false);
      }
    }
  };

  // const dummyPasswordField = (
  //   <input
  //     type="password"
  //     autoComplete="new-password"
  //     style={{ display: "none" }}
  //     // readOnly
  //   />
  // );
  // Function to handle forget password request
  const handleForgetPassword = () => {
    navigate("/forgetpassword");
    setForgetPassword(true);
  };

  const Captchalogic =value=>{
    console.log(value);
    setRecaptchaValue(value)
  }

  return (
    // Main login page container
    <div className="  ">
      {/* Header section */}
      <div className="bg-[#35404b] h-[13vh] px-10 py-3 flex justify-between items-center md:flex sm:grid ">
        <div className="flex items-center ">
          {/* Quillionz Logo */}
          <img src={quillionzLogoImage} alt="Logo" className="w-[90%] " />
        </div>
      </div>

      {/* Main content starts */}
      <div className="flex h-[87vh] overflow-hidden">
        {/* Left side content starts */}
        <div className=" w-1/2 flex bg-[#f17322] overflow-hidden">
          {/* Left side images */}
          <div className="w-1/2 ">
            <div className="">
              <img src={loginHowItWorks1Image} alt="Logo" className="" />
            </div>
            <div className="">
              <img src={loginHowItWorks2Image} alt="Logo" className="" />
            </div>
          </div>

          {/* Explanation text */}
          <div className="w-1/2 text-white flex flex-col align-middle place-content-center justify-center items-center text-center">
            {/* Steps with icons */}
            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginCopyPasteImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">1. Submit Content</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginChooseKeywordsImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">2. Choose Keywords</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginReviewContentImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">3. Review Content</p>
            </div>

            <div className="flex flex-col items-center justify-center ">
              <p>
                <img src={loginGetQuestionsImage} alt="Logo" className="" />
              </p>
              <p className="my-3 text-sm">4. Get Question and Notes Ideas</p>
            </div>
          </div>
        </div>
        {/* Left side content ends*/}

        {/* Right side login form starts */}
        <div className="w-1/2 flex flex-col justify-center items-center animate-fade-slide-down">
          {/* Quillionz Logo */}
          <div className="text-center flex align-middle justify-center  place-content-center mb-7">
            <img src={quillionzLoginLogoImage} alt="Logo" className="w-[49%]" />
          </div>

          <div className="text-center w-[25vw]">
            {/* Social media login options */}
            {/* <div className="">
              <Tooltip
                title="Sign in with Facebook"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`md:w-[25vw] sm:w-[28vw] mt-2 hover:opacity-90 bg-[#4264aa] text-white   flex items-center `}
                >
                  <span className="text-[28px] px-2 ">
                    <i className="fab fa-facebook-square"></i>
                  </span>
                  <span className=" signup-with-facebook font-semibold lg:mx-0 md:mx-0  sm:mx-0 ">
                    Sign in with Facebook
                  </span>
                </button>
              </Tooltip>

              <Tooltip
                title="Sign in with Google"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`md:w-[25vw] sm:w-[28vw] mt-2 hover:opacity-90 bg-[#dd4b39] text-white   flex items-center `}
                >
                  <span className="text-[28px] px-2 ">
                    <i className="fab fa-google"></i>
                  </span>
                  <span className=" signup-with-facebook font-semibold lg:mx-0 md:mx-0  sm:mx-0">
                    Sign in with Google
                  </span>
                </button>
              </Tooltip>
            </div> */}
            {/* Divider */}
            {/* <div className="my-3">
              <Root>
                <Divider>OR</Divider>
              </Root>
            </div> */}
            {/* Error message */}
            <div>
              {errorMessage && (
                <p className="text-red-500 animate-fade-slide-down text-[13px] signup-error-message w-full border border-red-500  p-2  mb-3">
                  {errorMessage}
                </p>
              )}
              {/* Email input */}

              <p className="mb-3">
                <input
                  className="w-full border border-[#ced4da] p-2  "
                  placeholder="Email"
                  value={userData.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    setUserData({ ...userData, email: value });
                    setQuery(value);
                    handleEmailChange(value);
                  }}
                />
                {errors.email && (
                  <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message">
                    {errors.email}
                  </span>
                )}
              </p>

              {/* Password input */}
              <p className="mb-3">
                <input
                  className="w-full border border-[#ced4da] p-2  "
                  placeholder="Password"
                  type="password"
                  value={userData.password}
                  onChange={handlePasswordChange}
                />
                {errors.password && (
                  <span className="text-red-500 antialiased animate-fade-slide-down text-[13px] signup-error-message text-start">
                    {errors.password}
                  </span>
                )}
              </p>
            </div>
            {/* Remember me and forget password */}
            <div className="flex justify-between text-sm my-4">
              <p className=" flex text-[#777e8d] items-center">
                <input
                  type="checkbox"
                  name=""
                  id=" "
                  className="cursor-pointer"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <span
                  className="items-center px-2 cursor-pointer"
                  onClick={() => setRememberMe(!rememberMe)}
                >
                  Remember me
                </span>
              </p>

              <p className="text-[#0d3f59] ">
                <button className="" onClick={handleForgetPassword}>
                  <span className="transition ease-in-out delay-150  underline cursor-pointer hover:no-underline ">
                    Forget Password
                  </span>
                </button>
              </p>
            </div>
            {/* TODO: We are using this terms and condition checkbox */}
            {/* Agreement checkbox */}
            {showprivacyPolicys === null && (
              <div className="checkbox-container flex items-center my-5">
                <input
                  type="checkbox"
                  name="agreeCheckbox"
                  id="agreeCheckbox"
                  className="cursor-pointer h-5 w-5 mb-[6rem]"
                  checked={agreeChecked}
                  onChange={handleAgreeCheckboxChange}
                />
                <label
                  htmlFor="agreeCheckbox"
                  className="ml-2 text-[#777e8d] cursor-pointer "
                >
                  By clicking the consent checkbox and providing my Personal
                  Data to Harbinger, I agree to the usage of my Personal Data by
                  Harbinger for the purposes mentioned under the{" "}
                  <a
                    href="https://www.quillionz.com/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#007BFF" }}
                  >
                    Privacy
                  </a>{" "}
                  Notice.
                </label>
              </div>
            )}
              {
                <div>
              <ReCAPTCHA sitekey={SITE_KEY} onChange={Captchalogic} ref={captchRef} />
              </div>
            }
            {/* Login button */}
            <div>
              <Tooltip
                title="Login"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button
                  className={`rounded w-[25vw] mt-2 py-2 hover:opacity-90 bg-[#f4833d] border-[#f4833d] text-white   flex items-center text-center place-content-center`}
                  onClick={handleLogin}
                >
                  <span className=" font-semibold mx-16">LOGIN</span>
                </button>
              </Tooltip>
            </div>
            {/* Sign up link */}
            <div className="mb-4 py-4 text-sm">
              <p>
                <span className="text-[#0d3f59] ">
                  Don't have a Quillionz account?{" "}
                </span>
                <Tooltip
                  title="Sign up here"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  <span onClick={navtosignupprice}>
                    <span className=" cursor-pointer text-[#007bff] hover:underline">
                      Sign up here.
                    </span>
                  </span>
                </Tooltip>
              </p>
            </div>
          </div>
        </div>
        {/* Right side login form starts */}

        {/* Copyright section starts */}
        <div className="copyright">
          <div className="copyright-text">
            © 2023 Harbinger AI Inc. All rights reserved.
          </div>
        </div>
        {/* Copyright section starts */}

        {/* Right side login form ends */}
      </div>
      {/* Main content ends*/}

      {/* Loading overlay */}
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

export default LoginPage;
