import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import SubmitContent from "./client/pages/SubmitContent/SubmitContent";
import Keywords from "./client/pages/Keywords/Keywords";
import Question from "./client/pages/Question/Question";
import ReviewContent from "./client/pages/ReviewContent/ReviewContent";
import ClosedCaption from "./client/pages/ClosedCaption/ClosedCaption";
import Layout from "./client/layout/Layout";
import MissingPage from "./client/components/Error/MissingPage";
import RequireAuth from "./client/auth/RequireAuth";
import QuillionzProSignup from "./client/pages/QuillionzPro/QuillionzProSignup";
import TermsOfUse from "./client/pages/TermsOfUse/TermsOfUse";
import SignupPage from "../../client/src/client/pages/signup/SignupPage";
import LoginPage from "../../client/src/client/pages/login/LoginPage.jsx";
import AccountInformation from "./client/pages/AccountInformation/AccountInformation";
import useCanvasDetection from "./client/hooks/useCanvasDetection";
import ChangePassword from "./client/pages/AccountInformation/ChangePassword";
import ForgetPassword from "./client/pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./client/pages/ResetPassword/ResetPassword";
import GenerateQuestionsModal from "./client/components/modals/GenerateQuestionsModal";
import ContentReadinessModal from "./client/components/modals/ContentReadinessModal";
import HistoryPage from "./client/pages/History/HistoryPage";
import ConfirmFileUploadModal from "./client/components/modals/ConfirmFileUploadModal.jsx";

function App() {
  // Detect if the user's device supports canvas rendering
  const isCanvas = useCanvasDetection();

  const isPlanExpired = localStorage.getItem("isPlanExpired");

  useEffect(() => {
    // Check if the URL contains a "source" parameter
    const url = new URL(window.location.href);
    if (url.searchParams.has("source")) {
      // Set a session storage flag for canvas support
      sessionStorage.setItem("canvas", "true");
      // Set a session storage flag for Quillionz 2.0
      sessionStorage.setItem("qz2.0", "true");
    } else {
      // If no "source" parameter is found, set canvas support flag to false
      sessionStorage.setItem("canvas", "false");
      // Remove the Quillionz 2.0 flag
      sessionStorage.removeItem("qz2.0");
    }
  }, []);

  // Get the navigation function from useHistory
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // ! isCanvas ? "h-[75vh]" : "h-[55vh]" Remove this later....

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/submitcontent" element={<SubmitContent />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/quillionzpro" element={<QuillionzProSignup />} />

        {/* Display different pages based on the 'isCanvas' flag */}
        <Route
          path="/"
          element={
            isCanvas ? <SubmitContent hasCanvas={isCanvas} /> : <LoginPage />
          }
          // element={isLoggedIn ? <SubmitContent /> : <LoginPage />}
        />
        <Route path="/pagenotfound" element={<MissingPage />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />

        {/* Protected Routes */}
        {/* <Route element={<RequireAuth />}> */}
        <Route exact path="/jjj" element={<ConfirmFileUploadModal />} />

        <Route exact path="/keywords" element={<Keywords />} />
        <Route exact path="/review" element={<ReviewContent />} />
        <Route exact path="/question" element={<Question />} />
        <Route exact path="/history" element={<HistoryPage />} />
        <Route exact path="/closedcaption" element={<ClosedCaption />} />
        <Route exact path="/userinfo" element={<AccountInformation />} />
        <Route exact path="/changepassword" element={<ChangePassword />} />
      </Route>

      {/* Catch All  */}
      <Route exact path="*" element={<MissingPage />} />
      {/* </Route> */}
    </Routes>
  );
}

export default App;
