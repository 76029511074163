import { useEffect, useState } from "react";

export const useDescriptiveQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [descriptiveQuestions, setDescriptiveQuestions] = useState([]);

  const handleDescriptiveQuestionChange = (index, newValue) => {
    const updatedQuestions = [...descriptiveQuestions];
    updatedQuestions[index].question = newValue;
    setDescriptiveQuestions(updatedQuestions);
  };

  const handleDescriptiveAnswerChange = (index, newValue) => {
    const updatedQuestions = [...descriptiveQuestions];
    updatedQuestions[index].answer = newValue;
    setDescriptiveQuestions(updatedQuestions);
  };

  const handleDescriptiveQuestionsEdit = (index) => {
    const updatedQuestions = [...descriptiveQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editing = !updatedQuestions[index].editing;
      setDescriptiveQuestions(updatedQuestions);
    }
  };

  const handleDescriptiveAnswersEdit = (index) => {
    const updatedQuestions = [...descriptiveQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setDescriptiveQuestions(updatedQuestions);
    }
  };

  const handleDescriptiveQuestionDelete = (index) => {
    let updatedQuestions = [...descriptiveQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    setTotalQuestionNumber(totalQuestionNumber - 1);
    setDescriptiveQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      setDescriptiveQuestions(
        generatedQuestionsFromSubmitContent.response?.descriptive_questions
          ?.questions ||
          generatedQuestionsFromSubmitContent.response?.descriptive_questions ||
          generatedQuestionsFromSubmitContent.questionset?.descriptive_questions
            ?.questions ||
          generatedQuestionsFromSubmitContent.questionset
            ?.descriptive_questions ||
          []
      );
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    descriptiveQuestions,
    handleDescriptiveQuestionChange,
    handleDescriptiveAnswerChange,
    handleDescriptiveQuestionsEdit,
    handleDescriptiveAnswersEdit,
    handleDescriptiveQuestionDelete,
  };
};
