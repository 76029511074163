import React, { useState } from 'react';
import Tooltip from "@mui/material/Tooltip";
import { Zoom, Menu, MenuItem, Button } from "@mui/material";

const TrySampleText = ({handleSampleButtonClick, isDropupOpen, toggleDropup , sampleButtonText }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleDropup(); // Toggle the drop-up on button click
  };

  const handleClose = () => {
    setAnchorEl(null);
    toggleDropup(); // Close when selecting an item
  };

  const handleSelect = (option) => {
    console.log(`Selected: ${option}`);
    handleClose();
  };

  return (
    <div className="relative text-[#f4833d] cursor-pointer text-center rounded border border-[#f4833d]">
      <Tooltip
        title="Try a Sample Text"
        arrow
        placement="top"
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 300 }}
      >
        <Button
          className="dropdown-button title-input w-[18vw] py-2 flex align-middle place-content-center justify-center items-center hover:bg-[#929292] hover:text-white rounded"
          onClick={handleButtonClick}
        >
          <span className='text text-orange-400'>{sampleButtonText}
          <span>
            <i className="fa-solid fa-caret-up mx-4"></i>
          </span>
          </span>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top', // Position the dropdown above the button
            horizontal: 'center', // Align the dropdown horizontally to the center of the button
          }}
          transformOrigin={{
            vertical: 'bottom', // Align the bottom of the dropdown to the top of the button
            horizontal: 'center', // Align the dropdown horizontally to the center of the button
          }}
      >
        <MenuItem className="dropdown-item-domain hover:rounded-xl" onClick={() => {handleSampleButtonClick("Elementary School"); handleClose();}}>
          Elementary School
        </MenuItem>
        <MenuItem className="dropdown-item-domain hover:rounded-xl" onClick={() => {handleSampleButtonClick("Junior College"); handleClose();}}>
          Junior College
        </MenuItem>
        <MenuItem className="dropdown-item-domain hover:rounded-xl" onClick={() => {handleSampleButtonClick("Graduation in STEM"); handleClose();}}>
          Graduation in STEM
        </MenuItem>
        <MenuItem className="dropdown-item-domain hover:rounded-xl" onClick={() => {handleSampleButtonClick("Doctorate"); handleClose();}}>
          Doctorate
        </MenuItem>
        <MenuItem className="dropdown-item-domain hover:rounded-xl" onClick={() => {handleSampleButtonClick("Corporate"); handleClose();}}>
          Corporate
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TrySampleText;
