import { useEffect, useState } from "react";

export const useInterpretiveQuestions = (
  generatedQuestionsFromSubmitContent,
  setTotalQuestionNumber,
  totalQuestionNumber
) => {
  const [interpretiveQuestions, setInterpretiveQuestions] = useState([]);

  const handleInterpretiveQuestionChange = (index, newValue) => {
    const updatedQuestions = [...interpretiveQuestions];
    updatedQuestions[index].question = newValue;
    setInterpretiveQuestions(updatedQuestions);
  };

  const handleInterpretiveAnswerChange = (index, newValue) => {
    const updatedQuestions = [...interpretiveQuestions];
    updatedQuestions[index].answer = newValue;
    setInterpretiveQuestions(updatedQuestions);
  };

  // const handleInterpretiveQuestionsEdit = (index) => {
  //   const updatedQuestions = [...interpretiveQuestions];
  //   if (updatedQuestions[index]) {
  //     updatedQuestions[index].editing = !updatedQuestions[index].editing;
  //     setInterpretiveQuestions(updatedQuestions);
  //   }
  // };

  const handleInterpretiveQuestionsEdit = (index) => {
    const updatedQuestions = [...interpretiveQuestions];
    updatedQuestions[index].editing = !updatedQuestions[index].editing;

    if (updatedQuestions[index].editing) {
      // After setting editing to true, focus on the contentEditable div for the question
      setTimeout(() => {
        const contentEditableElement = document.getElementById(
          `question-${index}`
        ); // Add an ID to your contentEditable element
        if (contentEditableElement) {
          contentEditableElement.focus();
        }
      }, 0);
    }

    setInterpretiveQuestions(updatedQuestions);
  };

  const handleInterpretiveAnswersEdit = (index) => {
    const updatedQuestions = [...interpretiveQuestions];
    if (updatedQuestions[index]) {
      updatedQuestions[index].editingAnswer =
        !updatedQuestions[index].editingAnswer;
      setInterpretiveQuestions(updatedQuestions);
    }
  };

  const handleInterpretiveQuestionDelete = (index) => {
    let updatedQuestions = [...interpretiveQuestions];
    updatedQuestions.splice(index, 1); // Remove the question at the given index
    // setTotalQuestionNumber(totalQuestionNumber - 1);
    setInterpretiveQuestions(updatedQuestions);
  };

  useEffect(() => {
    if (generatedQuestionsFromSubmitContent) {
      setInterpretiveQuestions(
        generatedQuestionsFromSubmitContent.response?.interpretive_questions
          ?.questions ||
          generatedQuestionsFromSubmitContent.response
            ?.interpretive_questions ||
          generatedQuestionsFromSubmitContent.questionset
            ?.interpretive_questions?.questions ||
          generatedQuestionsFromSubmitContent.questionset
            ?.interpretive_questions ||
          []
      );
    }
  }, [generatedQuestionsFromSubmitContent]);

  return {
    interpretiveQuestions,
    handleInterpretiveQuestionChange,
    handleInterpretiveAnswerChange,
    handleInterpretiveQuestionsEdit,
    handleInterpretiveAnswersEdit,
    handleInterpretiveQuestionDelete,
  };
};
