import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import QuillionzProUpgradeModal from "../../components/modals/QuillionzProUpgradeModal";
import { fetchUsersProfile } from "../../api/fetchUserProfile";

const getFormattedMonth = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short", year: "numeric", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const plans = {
  1: "Monthly",
  3: "Quarterly",
  12: "Annual",
};

const AccountInformation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access the 'PayerID' query parameter
  const payerID = queryParams.get("PayerID");

  // The rest of your existing logic...

  const navigate = useNavigate();

  const userEmail = localStorage.getItem("email");

  const userName = localStorage.getItem("username");

  const unlimitedUser = localStorage.getItem("userGPTKey") !== "LIMITED";

  const [isUnlimitedUser, setIsUnlimitedUser] = useState(unlimitedUser);

  const [subscriptionPlan, setSubscriptionPlan] = useState("");

  // Effect hook to update subscription plan name based on the value
  useEffect(() => {
    async function getUsersProfile() {
      const { data, status } = await fetchUsersProfile(userEmail);
      if ([200, 201].includes(status)) {
        const {
          userData: { subscription },
        } = data;
        localStorage.setItem(
          "planExpiryDate",
          getFormattedMonth(subscription.ExpiryDate)
        );
        setSubscriptionPlan(
          plans[subscription.Plan_Validity] || "Unknown Plan"
        );
      }
    }
    if (payerID) {
      getUsersProfile();
    } else {
      const validity = localStorage.getItem("subscriptionPlan");
      setSubscriptionPlan(plans[validity] || "Unknown Plan");
    }
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts.

  const [showQuillionzProUpgradeModal, setShowQuillionzProUpgradeModal] =
    useState(false);

  // Function to handle Quillionz Pro Upgrade Modal
  const handleQuillionzProUpgradeModal = () => {
    setShowQuillionzProUpgradeModal(true);
  };

  // Function to Quillionz Pro Upgrade Modal
  const handleQuillionzProUpgradeModalClose = () => {
    setShowQuillionzProUpgradeModal(false);
  };

  // Function to change the password
  const handleChangePassword = () => {
    navigate("/changepassword");
  };

  const isPlanExpired = localStorage.getItem("isPlanExpired");
  return (
    <div className="">
      <AppNavigation />
      <div className="flex  flex-col justify-center items-center  h-[81vh]">
        <div className="py-5">
          <h1 className="text-[#212529] text-3xl font-medium">
            Account Information
          </h1>
        </div>

        <div className=" p-5 w-full  max-w-max rounded-lg shadow-md">
          {/* Username */}
          <div className="flex justify-start space-x-28 mb-8">
            <p className="font-semibold">Name:</p>
            <p className="px-3">{userName}</p>
          </div>

          {/* Email */}
          <div className="flex justify-start space-x-32  w-full mb-8">
            <p className="font-semibold">Email:</p>
            <p>{userEmail}</p>
          </div>

          {/* Account Type */}
          <div className="flex justify-start space-x-24 mb-8">
            <p className="font-semibold">Type:</p>
            <p className="text-green-600 font-medium px-9">
              {isUnlimitedUser ? "Unlimited" : (subscriptionPlan === 'Unknown Plan' ? 'Free' : 'Paid')}
            </p>
          </div>

          {/* Payment Status */}
          <div className="flex justify-start space-x-10 mb-8">
            <p className="font-semibold">Payment Status:</p>
            <p>
              <span className="text-green-600 font-medium px-3">{subscriptionPlan === 'Unknown Plan'  ? 'NA' : 'Active'}</span>{" "}
             {subscriptionPlan !== 'Unknown Plan' && <span>(Paypal subscription)</span>}
            </p>
          </div>

          {/* Validity */}
          {!isUnlimitedUser && subscriptionPlan !== 'Unknown Plan' && (
            <div className="flex justify-start space-x-28">
              <p className="font-semibold">Validity:</p>
              <p className="">
                <span>
                  Your{" "}
                  <span className="text-[#f4833d] font-semibold">
                    {subscriptionPlan}
                  </span>{" "}
                  {/* TODO : Uncomment this after taking confirmation from Team */}
                  {/* {isPlanExpired == "false" ? (
                    <span>subscription is valid till</span>
                  ) : (
                    <span>subscription expired on</span>
                  )} */}
                  <span>subscription is valid till</span>
                </span>{" "}
                <span className="text-[#f4833d] font-medium">
                  {localStorage.getItem("planExpiryDate")}
                </span>
              </p>
            </div>
          )}
        </div>

        {/* Buttons */}
        <div className="border-t flex pt-8 w-full max-w-md">
          <Tooltip
            title="Change your password"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              onClick={handleChangePassword}
              className="mt-2 hover:opacity-90 
        bg-[#f4833d]
        text-white px-4 py-2 rounded block mx-auto"
            >
              Change Password
            </button>
          </Tooltip>

          {!isUnlimitedUser && (
            <Tooltip
              title="Manage Subscription"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                className="mt-2 hover:opacity-90 
          bg-[#f4833d]
          text-white px-4 py-2 rounded block mx-auto"
                onClick={handleQuillionzProUpgradeModal}
              >
                Manage Subscription
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      {/* Render the Quillionz Pro Upgrade Modal component */}
      {showQuillionzProUpgradeModal && (
        <QuillionzProUpgradeModal
          isOpen={showQuillionzProUpgradeModal} // Pass the state to control whether the modal is open or not
          onClose={handleQuillionzProUpgradeModalClose} // Pass the function to close the modal and redirect
        />
      )}

      {/* Main Footer */}
      <div className="flex-grow ">
        <MainFooter />
      </div>
    </div>
  );
};

export default AccountInformation;
