/**
 * @author : Amol Rathod
 */

import { getKeywords } from "../../api/getKeywords";
import {
  commonErrorToastMessage,
  emptyDomainNameError,
  emptyTitleNameError,
  wordCountError,
} from "../../components/toastify/AllToastifyErrors";

/**
 * Navigates to the keywords page with necessary data if input validation passes.
 *
 * @param {string} title - The title input.
 * @param {string} domain - The selected domain.
 * @param {number} wordCount - The word count.
 * @param {string} doc_id - The document ID.
 * @param {string} content - The document content.
 * @param {Object} responseData - Response data object.
 * @param {RefObject} titleInputRef - Ref for the title input element.
 * @param {RefObject} domainSelectRef - Ref for the domain select element.
 * @param {function} setIsLoading - Function to set loading state.
 * @param {function} setIsTextLoading - Function to set text loading state.
 * @param {function} navigate - Function for navigation.
 * @param {File} selectedFile - The selected file.
 */
export const navigateToKeywords = async (
  title,
  domain,
  wordCount,
  doc_id,
  content,
  responseData,
  titleInputRef,
  domainSelectRef,
  setIsLoading,
  setIsTextLoading,
  navigate,
  selectedFile
) => {
  // Check if title is empty
  if (!title) {
    commonErrorToastMessage("Please add title for your content.");
    titleInputRef?.current?.focus();
    return;
  }

  // Check if domain is empty
  if (!domain) {
   domain = 'Other'
  }
  // Check if word count is less than 300
  if (wordCount < 300 || wordCount > 3000) {
    commonErrorToastMessage(
      "Word count must be between 300-3000 for question generation."
    );
    return;
  }

  try {
    // Set loading states
    setIsLoading(true);
    setIsTextLoading(true);

    // Fetch keywords from PDF
    const keywordsResponseFromPDF = await getKeywords(doc_id, title, content);

    // Reset loading states
    setIsTextLoading(false);
    setIsLoading(false);

    // Create a query string with relevant data
    // const queryString = new URLSearchParams({
    //   responseData: JSON.stringify(responseData),
    //   responseToKeywordPage: JSON.stringify(keywordsResponseFromPDF),
    //   domainName: JSON.stringify(domain),
    //   titleName: JSON.stringify(title),
    //   content: JSON.stringify(content),
    // }).toString();

    localStorage.setItem("content", content);
    localStorage.setItem("title", title);
    localStorage.setItem("doc_id", responseData.doc_id);
    localStorage.setItem("domain", domain);
    localStorage.setItem(
      "keywordsResponse",
      JSON.stringify(keywordsResponseFromPDF)
    );

    // Retrieve and console log keywordsResponse from local storage
    const keywordsResponseFromLocalStorage = JSON.parse(
      localStorage.getItem("keywordsResponse")
    );

    // Navigate to the keywords page
    navigate(`/keywords`);
  } catch (error) {
    // Handle API request error
    console.error("Error while making the API request:", error.message);
    setIsLoading(false);
    setIsTextLoading(false);

    commonErrorToastMessage("Error while generating keywords.");
  } finally {
    // Reset loading state if no selected file
    if (!selectedFile) {
      setIsLoading(false);
      setIsTextLoading(false);
    }
  }
};
