/**
 * @author : Amol Rathod
 * @description : This function sends user feedback to the server using Axios.
 */

import axios from "../api/axios";

/**
 * Sends user feedback to the server.
 *
 * @param {email} email - The email Id of the person to whom the user want to send invitation.
 */
export const inviteAFriend = async (username, emails, message , recaptchaValue) => {
  try {
    // Send a POST request to the server to submit the review content.
    const response = await axios.post("/invite_friend", {
      username,
      emails,
      message,
      recaptchaValue
    });

    // Return the response from the server.
    return response;
  } catch (error) {
    // If an error occurs, throw the error for handling at a higher level.
    throw error;
  }
};
