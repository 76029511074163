
/**
 * @author : Piyush Saini
 * @description :  Paypal Forms for different subscription plan
 */

/**
 * @param {string} email - A buyer email to sent to paypal.
 * @returns {jsx} - JSX Annual form element .
 */
export const yearlyForm = (email) => {
    return (
      // ADD THIS target="_blank" TO OPEN PAYPAL IN NEW TAB
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" onsubmit="target_popup(this)" target="_blank"  novalidate="novalidate">  
            <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="ADZ3CQY47QAXW" />
                <input type="hidden" name="business" value="rij@harbingergroup.com" />
                <input type="hidden" name="AccountId" value="6181" />
                <input type="hidden" name="item_name_temp" value="2" />
                <input type="hidden" name="item_name" value="2" />
                <input type="hidden" name="item_number" value="2" />
                <input type="hidden" name="amount" value="1" />
                <input type="hidden" name="no_shipping" value="1" />
                <input type="hidden" name="no_note" value="1" />
                <input type="hidden" name="currency_code" value="USD"/>
                <input type="hidden" name="bn" value="IC_Sample"/>
                <input type="hidden" name="page_style" value="Quillionz" />
                <input type="hidden" name="return" value={`${window.location.origin}/userinfo`} />
                <input type="hidden" name="custom" value={`Quillionz_${email}_QZGPT_gpt`}/>
                <input type="hidden" name="RoleId" value="7" />
                <button type="submit" id="getPremiumQuillionz" class="mt-2 hover:opacity-90 bg-[#f4833d] text-white px-4 py-2 rounded w-full" data-item="1">Annual ($99.99)</button>
            </fieldset>
        </form>
    )
}

export const quaterlyForm = (email) => {
    return (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        onsubmit="target_popup(this)"
        novalidate="novalidate"
        target="_blank"     // ADD THIS target="_blank" TO OPEN PAYPAL IN NEW TAB
      >
        <fieldset>
          <input type="hidden" name="add" value="1" />
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="WDRQGA8ZAJ87N" />
          <input type="hidden" name="business" value="rij@harbingergroup.com" />
          <input type="hidden" name="AccountId" value="6181" />
          <input type="hidden" name="item_name_temp" value="2" />
          <input type="hidden" name="item_name" value="2" />
          <input type="hidden" name="item_number" value="2" />
          <input type="hidden" name="amount" value="1" />
          <input type="hidden" name="no_shipping" value="1" />
          <input type="hidden" name="no_note" value="1" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="bn" value="IC_Sample" />
          <input type="hidden" name="page_style" value="Quillionz" />
          <input
            type="hidden"
            name="return"
            value={`${window.location.origin}/userinfo`}
          />
          <input
            type="hidden"
            name="custom"
            value={`Quillionz_${email}_QZGPT_gpt`}
          />
          <input type="hidden" name="RoleId" value="7" />
          <button
            type="submit"
            id="getPremiumQuillionz"
            class="mt-2 hover:opacity-90 bg-[#f4833d] text-white px-4 py-2 rounded w-full"
            data-item="1"
          >
            Quarterly ($29.99)
          </button>
        </fieldset>
      </form>
    );
}

export const monthlyForm = (email) => {
    return (
       // ADD THIS target="_blank" TO OPEN PAYPAL IN NEW TAB
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" onsubmit="target_popup(this)" target="_blank"  novalidate="novalidate">
            <fieldset>
                <input type="hidden" name="add" value="1" />
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="KP2R6HDEJSFVS" />
                <input type="hidden" name="business" value="rij@harbingergroup.com" />
                <input type="hidden" name="AccountId" value="6181" />
                <input type="hidden" name="item_name_temp" value="2" />
                <input type="hidden" name="item_name" value="2" />
                <input type="hidden" name="item_number" value="2" />
                <input type="hidden" name="amount" value="1" />
                <input type="hidden" name="no_shipping" value="1" />
                <input type="hidden" name="no_note" value="1" />
                <input type="hidden" name="currency_code" value="USD"/>
                <input type="hidden" name="bn" value="IC_Sample"/>
                <input type="hidden" name="page_style" value="Quillionz" />
                <input type="hidden" name="return" value={`${window.location.origin}/userinfo`} />
                <input type="hidden" name="custom" value={`Quillionz_${email}_QZGPT_gpt`}/>
                <input type="hidden" name="RoleId" value="7" />
                <button type="submit" id="getPremiumQuillionz" class="mt-2 hover:opacity-90 bg-[#f4833d] text-white px-4 py-2 rounded w-full" data-item="1">Monthly ($14.99)</button>
            </fieldset>
        </form>
    )
}