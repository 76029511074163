import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import StepProgress from "../../components/stepper/StepProgress";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import GenerateQuestionsModal from "../../components/modals/GenerateQuestionsModal"; // Import the modal component
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import ReviewContentModal from "../../components/modals/ReviewContentModal";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import { commonErrorToastMessage } from "../../components/toastify/AllToastifyErrors";
import ContentReadinessModal from "../../components/modals/ContentReadinessModal";
import { reviewContent } from "../../api/reviewContent";
import {reviewContentDummyResponse,} from "../../data/sampleTextDummyResponse/dummyResponsesSampleText";
import { sampleQuestions , sampleQAElementarySchool , sampleQAJuniorClg , sampleQAGraduation ,sampleQADoctorate , sampleQACorporate} from "../../data/SubmitContent/sampleText/sampleText";
import { getQuestionAnswer } from "../../api/getQuestionAnswer";
const Keywords = () => {
  // Use the useLocation hook to get the location object
  const location = useLocation();

  const [contentReadiness, setContentReadiness] = useState(0);

  const [responseToReviewContent, setResponseToReviewContent] = useState("");

  // Extract the query parameter data from the "Submit Content" page.
  const responseDataFromSubmitContent = JSON.parse(
    localStorage.getItem("keywordsResponse")
  );

  // Doc Id of the Content
  const doc_id = localStorage.getItem("doc_id");

  // Domain name of the Content
  // const domainNameFromSubmitContent = JSON.parse(
  //   new URLSearchParams(location.search).get("domainName")
  // );

  const domain = localStorage.getItem("domain");

  // Title of the Content
  const title = localStorage.getItem("title");

  // Content Data
  const content = localStorage.getItem("content");

  const titleName = JSON.parse(
    new URLSearchParams(location.search).get("titleName")
  );

  // const content = JSON.parse(
  //   new URLSearchParams(location.search).get("content")
  // );

  // Add a new state variable to track the loading state
  const [isLoading, setIsLoading] = React.useState(false);

  // Set the active step value to 1
  const activeStep = 2

  const navigate = useNavigate();

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerateQuestionsModalOpen, setIsGenerateQuestionsModalOpen] =
    useState(false);

  const [showMore, setShowMore] = useState(false);

  const handleToggleClick = () => {
    setShowMore(!showMore);
    // scrollToKeyword(showMore ? 0 : dummyKeywords.length - 1);
  };

  const scrollToKeyword = (index) => {
    const keywordContainers = document.querySelectorAll(".keywords-container");
    if (keywordContainers && keywordContainers.length > index) {
      keywordContainers[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  let contentReadinessMeter;

  // Function to handle Review Content API
  const handleReviewContent = async () => {
    let responseDataFromKeywords = "";
    try {
      // setIsLoading(true);

      // This is mechanism is only for Sample Text
      const isSampleTextClicked = localStorage.getItem("isSampleButtonClicked");

      // reviewContent API calling
      // if (isSampleTextClicked === "true") {
      //   // reviewContentDummyResponse is the dummy response which contains static review content data for Sample Text
      //   const responseDataFromKeywordsTemp = reviewContentDummyResponse;

      //   responseDataFromKeywords = responseDataFromKeywordsTemp;
      // } else {
      //   // ! Later, we'll utilize the 'reviewContent' API to evaluate the Review Content and obtain a content readiness meter (After go live).
      //   // ! Review Content Logic Skipping Starts...
      //   const responseDataFromKeywordsTemp = await reviewContent(
      //     doc_id,
      //     title,
      //     content
      //   );
      //   responseDataFromKeywords = responseDataFromKeywordsTemp;
      // }

      // // Don't use this . This is an optional part used when we don't have 'Try A Sample Text'.
      // // const responseDataFromKeywordsTemp = await reviewContent(
      // //   doc_id,
      // //   title,
      // //   content
      // // );
      // // responseDataFromKeywords = responseDataFromKeywordsTemp;

      // setResponseToReviewContent(responseDataFromKeywords);

      // // Content Readiness Logic
      // let totalSentences =
      //   responseDataFromKeywords?.data?.reviewContent?.countOfTotalSentences;

      // let complexSentence =
      //   responseDataFromKeywords?.data?.reviewContent.complexSentence.length;

      // let pronounSuggestion =
      //   responseDataFromKeywords?.data?.reviewContent.pronounSuggestion.length;

      // let subjectiveSentence =
      //   responseDataFromKeywords?.data?.reviewContent.subjectiveSentence.length;

      // let incompleteSentence =
      //   responseDataFromKeywords?.data?.reviewContent.incompleteSentence.length;

      // let fixTotalLength =
      //   complexSentence +
      //   pronounSuggestion +
      //   subjectiveSentence +
      //   incompleteSentence;

      // let totalSent = totalSentences;

      // let contentReadiness = (fixTotalLength * 100) / totalSent;

      // contentReadiness = 100 - parseInt(contentReadiness);

      // if (contentReadiness > 100 || contentReadiness < 0) {
      //   contentReadiness = 0;
      // }

      // contentReadinessMeter = contentReadiness;

      // setContentReadiness(contentReadiness); // Set the state variable

      // // Determine the background color class based on contentReadiness
      // let backgroundClass;

      // console.log(
      //   "Keyword Response from Review Content : ",
      //   responseDataFromKeywords
      // );

      // if (contentReadiness < 50) {
      //   backgroundClass = "bg-[#ee6867]";
      // } else if (contentReadiness >= 50 && contentReadiness < 90) {
      //   backgroundClass = "bg-[#f2ba5b]";
      // } else if (contentReadiness >= 90 && contentReadiness <= 100) {
      //   backgroundClass = "bg-[#5de79e]";
      // }

      // let contentReadinessLessThan50 = contentReadiness < 50;
      // let contentReadiness50to90 =
      //   contentReadiness >= 50 && contentReadiness < 90;
      // let contentReadinessGreaterThan90 =
      //   contentReadiness >= 90 && contentReadiness <= 100;

      // localStorage.setItem("contentReadiness", contentReadiness);
      // localStorage.setItem(
      //   "reviewContentResponse",
      //   JSON.stringify(responseDataFromKeywords)
      // );

      // // console.log("ContentReadiness Meter From Keywords : ", contentReadiness);

      // localStorage.setItem(
      //   "selectedKeywordsArray",
      //   JSON.stringify(selectedKeywords)
      // );

      // // navigate(`/review?${queryString}`);

      // localStorage.getItem("contentReadiness") > 90
      //   ? setIsModalOpen(true)
      //   : navigate(`/review`);

      // // navigate('/question')

      // // ! Review Content Logic Skipping Ends...

      // // ! At present, on the Keyword Page, we bypass the logic typically employed in 'Review Content'. Instead, we are directly calling two APIs: 'Review Content' and 'getQuestionAnswers', although the 'Review Content' function is not currently in active use.

      // // getQuestionsDummyResponse is the dummy response which contains static questions for Sample Text

      setIsLoading(false);

      setIsGenerateQuestionsModalOpen(true);

      // This is mechanism is only for Sample Text
      if (isSampleTextClicked === "true") {
        const remainingQuestionSets = localStorage.getItem("UserAttempts");
        switch (title) {
          case "The Lion and the Mouse":
            navigate("/question", {
              state: {
                generatedQuestions: sampleQAElementarySchool,
                userAttempts:remainingQuestionSets
              },
            });
            break;
  
            case "AI in Drug Discovery and Development":
              navigate("/question", {
                state: {
                  generatedQuestions: sampleQAJuniorClg,
                  userAttempts:remainingQuestionSets
                },
              });
              break;
  
            case "The Future of Renewable Energy Technologies":
              navigate("/question", {
                state: {
                  generatedQuestions: sampleQAGraduation,
                  userAttempts:remainingQuestionSets
                },
              });
              break;
  
            case "Adoption of AI in Biology":
              navigate("/question", {
                state: {
                  generatedQuestions: sampleQADoctorate,
                  userAttempts:remainingQuestionSets
                },
              });
              break;
  
            case "Mentorship and Succession Planning":
              navigate("/question", {
                state: {
                  generatedQuestions: sampleQACorporate,
                  userAttempts:remainingQuestionSets
                },
              });
              break;
  
            default:
            navigate("/question", {
              state: {
                generatedQuestions: sampleQuestions,
              },
            });
         }  
      } else {
        // !TODO : THIS PART IS REMAINING, DO IT LATER....
        try {
          const usergptKey = localStorage.getItem("userGPTKey");
          const email = localStorage.getItem("email");

          const reaminingQuestionSets = localStorage.getItem("UserAttempts");

          if (reaminingQuestionSets == 0 && usergptKey === "LIMITED") {
            commonErrorToastMessage(
              "You have exhausted your monthly 25 attempts. You can upgrade to Unlimited Plan."
            );
            // onClose();

            return;
          }

          const response = await getQuestionAnswer(
            doc_id,
            content,
            selectedKeywords,
            usergptKey,
            email
            // queryData
          );

          // Log the API response
          navigate("/question", {
            state: {
              generatedQuestions: response.data,
            },
          });
          console.log("Keywords Response : ", response.updatedAttempts);
          console.log("Keywords Response : ", response.data);

          if (
            response.data.response.no_ques !== 0 &&
            !response.data.response.error
          ) {
            // ! Even if questions aren't generated, the 'RemainingQuestionSets' count decreases by 1, but this updated count isn't reflected on the user interface.

            navigate("/question", {
              state: {
                generatedQuestions: response.data,
                userAttempts: response.data.updatedAttempts,
              },
            });
          } else {
            console.log(
              "Error without questions : ",
              response.data.response.error
            );
            console.error("Error while generating questions.");
            commonErrorToastMessage("Error while generating questions.");
            // onClose();
            setIsGenerateQuestionsModalOpen(false);
            setIsModalOpen(false);
            return;
          }
        } catch (error) {
          console.error("Error while making the API request:", error.message);
          commonErrorToastMessage("Error while generating questions.");
          setIsLoading(false);
          setIsModalOpen(false);
          setIsGenerateQuestionsModalOpen(false);
          // onClose();
        }
      }

      // localStorage.setItem("title", titleName);

      // navigate("/review", {
      //   state: { reviewContentResponse: response, contentData: contentData },
      // });

      setIsGenerateQuestionsModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error while making the API request :", error.message);
      commonErrorToastMessage("An error occurred while curating the content.");
      setIsLoading(false);
      setIsGenerateQuestionsModalOpen(false);
    } finally {
      setIsLoading(false);
      setIsGenerateQuestionsModalOpen(false);
    }

    // ! END of skipping Review Content
  };

  // State variable to store the selected keywords
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  // State variable to store the time taken to generate the response
  const [responseTime, setResponseTime] = useState(0);

  // State variable to store the text entered by the user
  const [newKeyword, setNewKeyword] = useState("");

  // State variable to store the keywords
  const [dummyKeywords, setDummyKeywords] = useState([]);

  useEffect(() => {
    if (
      responseDataFromSubmitContent &&
      Array.isArray(
        responseDataFromSubmitContent.data?.keywords?.final_keywords
      )
    ) {
      const topKeywords =
        responseDataFromSubmitContent.data.keywords.top_keywords.slice(0, 35);
      const finalKeywords =
        responseDataFromSubmitContent.data.keywords.final_keywords;

      // Combine both selected and unselected keywords into an array named 'dummyKeywords', which will be utilized for generating questions.
      setDummyKeywords([...topKeywords, ...finalKeywords]);

      // We automatically select the "Top Keywords" and use only these "Top Keywords" and "Final Keywords" to generate questions.
      setSelectedKeywords(topKeywords);
    }
  }, []);

  // Function to handle typing in the input field
  const handleKeywordChange = (event) => {
    setNewKeyword(event.target.value);
  };

  // Function to add the new keyword to the dummyKeywords array
  const handleAddKeyword = () => {
    const trimmedKeyword = newKeyword.trim();

    if (trimmedKeyword === "") {
      commonErrorToastMessage("Please add a keyword.");
      return; // Exit the function without adding the keyword
    }

    // Check if the keyword is already selected
    if (selectedKeywords.includes(trimmedKeyword)) {
      commonErrorToastMessage("This keyword is already selected.");
      setNewKeyword("");
      return; // Exit the function
    }

    // If the keyword is in dummyKeywords but not selected, move it to the first position
    if (dummyKeywords.includes(trimmedKeyword)) {
      setDummyKeywords((prevKeywords) => [
        trimmedKeyword,
        ...prevKeywords.filter((keyword) => keyword !== trimmedKeyword),
      ]);
    } else {
      // If the keyword is not in dummyKeywords, add it at the first position
      setDummyKeywords([trimmedKeyword, ...dummyKeywords]);
    }

    // Select the keyword and place it at the first position
    setSelectedKeywords([trimmedKeyword, ...selectedKeywords]);

    setNewKeyword(""); // Clear the input field after handling the keyword
  };

  // Function to handle clicking on a keyword
  const handleKeywordClick = (keyword) => {
    setSelectedKeywords((prevSelectedKeywords) => {
      // Check if the keyword is already selected
      if (prevSelectedKeywords.includes(keyword)) {
        // If it is selected, remove it from the selected keywords array
        const updatedKeywords = prevSelectedKeywords.filter(
          (k) => k !== keyword
        );
        // Return the updated array with the keyword removed
        return updatedKeywords;
      } else {
        // If it is not selected, add it to the selected keywords array
        const updatedKeywords = [...prevSelectedKeywords, keyword];
        // Return the updated array with the keyword added
        return updatedKeywords;
      }
    });
  };

  useEffect(() => {
    const unselectedKeywords = dummyKeywords.filter(
      (keyword) => !selectedKeywords.includes(keyword)
    );
  }, [selectedKeywords, dummyKeywords]);

  // console.log("Total Keywords : ", dummyKeywords.length);

  // Width of the Select components for difficulty level
  const selectWidth = 40;

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  // Function to handle generating questions
  const handleReviewStepModal = async () => {
    try {
      // setIsLoading(true)
      setIsModalOpen(true);

      // setIsLoading(true);
    } catch (error) {
      console.error("Error while generating questions:", error.message);
      setIsModalOpen(false);
    }
  };

  // Drag and drop keywords functionality (Optional)
  const [draggedIndex, setDraggedIndex] = useState(null);

  const onDragStart = (event, startIndex) => {
    setDraggedIndex(startIndex);
    event.dataTransfer.setData("startIndex", startIndex);
    event.currentTarget.classList.add("dragging");
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Necessary for allowing dropping
    event.currentTarget.classList.add("dropZone");
  };

  const onDragLeave = (event) => {
    event.currentTarget.classList.remove("dropZone");
  };

  const onDragEnd = (event) => {
    event.currentTarget.classList.remove("dragging");
  };

  const onDrop = (event, endIndex) => {
    event.currentTarget.classList.remove("dropZone");
    const startIndex = event.dataTransfer.getData("startIndex");

    const result = Array.from(dummyKeywords);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setDummyKeywords(result);
    setDraggedIndex(null);
  };

  const isCanvas = useCanvasDetection();

  return (
    <div className=" ">
      {/* {isCanvas ? null : <AppNavigation />} */}
      <AppNavigation />

      <div className="flex h-[81vh]">
        {/* Left side: Question Types and Difficulty Levels */}
        <div className="w-full px-6 pt-4 rounded-2xl mx-10 ">
          <div className=" border-b w-full pb-4 border-gray-300  ">
            <p className=" mx-auto  text-xl font-semibold text-center text-[#838996]">
              Help Quillionz generate better questions by selecting important
              keywords and deselecting others.
            </p>
          </div>

          <div className={` ${isCanvas ? "h-[65vh]" : "h-[60vh]"} `}>
            {/* Content for the question types */}
            <div
              className={`keywords-container border-b border-gray-300  flex-wrap flex place-content-start  custom-scrollbar overflow-x-hidden overflow-y-auto  ${
                isCanvas ? "h-[61vh]" : "h-[57vh]"
              }  mb-2 pb-8 pt-3 justify-center`}
              onDragOver={onDragOver}
            >
              {dummyKeywords
                .slice(0, showMore ? dummyKeywords.length : 50) // Display 50 (in retail we have 46) keywords when showMore is true
                .map((keyword, index) => (
                  <div
                    key={index}
                    // draggable
                    // onDragStart={(e) => onDragStart(e, index)}
                    // onDragOver={onDragOver}
                    // onDragLeave={onDragLeave}
                    // onDragEnd={onDragEnd}
                    // onDrop={(e) => onDrop(e, index)}
                    className={`flex mx-2 my-2 place-content-center
                     keyword ${draggedIndex === index ? "dragging" : ""}`}
                  >
                    <span
                      className={`keywords hover:opacity-70 transition-colors duration-300 ease-in-out  px-4 py-1 text-center rounded cursor-pointer  ${
                        selectedKeywords.includes(keyword)
                          ? " hover:shadow border-[1.5px] border-[#008000] text-[#595b5e] cursor-pointer transition-all ease-in-out duration-500 "
                          : "hover:shadow bg-[#fafafa] text-[#b5b5b5] border-[1px] border-[#dedee0] transition-all ease-in-out duration-500 "
                      }`}
                      onClick={() => handleKeywordClick(keyword)}
                    >
                      {keyword}
                    </span>
                  </div>
                ))}
            </div>

            {/* Present the "Show More" and "Show Less" options only if the number of dummyKeywords exceeds 50. */}
            {dummyKeywords.length > 50 ? (
              <div className="flex place-content-center">
                <button
                  className="mt-[-26px]  bg-white font-semibold text-[#212529] px-4 py-2"
                  onClick={handleToggleClick}
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className=" h-[8vh]">
            <div className="flex mb-3 mt-1 place-content-between">
              <div className="flex">
                <p className="me-4">
                  <input
                    type="text"
                    placeholder="Type your new keyword here"
                    className="px-4 py-2  border border-[#f4833d] w-60 rounded outline-[#f4833d]"
                    value={newKeyword}
                    onChange={handleKeywordChange}
                  />
                </p>

                <Tooltip
                  title="Add your new keyword"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  {/* Button to add the new keyword */}
                  <button
                    onClick={handleAddKeyword}
                    className="rounded hover:opacity-90 bg-[#f4833d] text-white px-4 py-2 transition-all ease-in-out duration-500"
                  >
                    Add
                  </button>
                </Tooltip>
              </div>

              <div className="">
                <Tooltip
                  title="Proceed your keywords"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 300 }}
                >
                  {/* Button to trigger the modal */}
                  <button
                    onClick={handleReviewContent} // Open the modal when the button is clicked
                    className="rounded hover:opacity-90 bg-[#f4833d] text-white px-4 py-2  transition-all ease-in-out duration-500"
                  >
                    Proceed
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>

          {/* Render the GenerateQuestionsModal component if the modal is open */}
          {isModalOpen && (
            <ReviewContentModal
              isOpen={isModalOpen} // Pass the state to control whether the modal is open or not
              onClose={handleModalClose} // Pass the function to close the modal and redirect
              responseData={responseToReviewContent} // Pass the responseDataFromSubmitContent as a prop
              contentData={content} // Pass the contentDataFromSubmitContent as a prop
              domainNameKeywordPage={domain}
              titleName={titleName}
              content={content}
              selectedKeywords={selectedKeywords}
              contentReadiness={contentReadiness}
            />
          )}
        </div>

        {/* Right side: Step Progress */}
        <div className="w-auto h-auto step-progress">
          <StepProgress activeStep={activeStep} />
        </div>
      </div>

      {/* Loading overlay */}
      {isLoading && <LoadingOverlay />}

      {isGenerateQuestionsModalOpen && (
        <GenerateQuestionsModal
          isOpen={isGenerateQuestionsModalOpen} // Pass the state to control whether the modal is open or not
          onClose={handleModalClose} // Pass the function to close the modal and redirect
          // Pass the time taken to generate the response as a prop
        />
      )}

      <MainFooter />
    </div>
  );
};

export default Keywords;
