import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import StepProgress from "../../components/stepper/StepProgress";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import GenerateQuestionsModal from "../../components/modals/GenerateQuestionsModal"; // Import the modal component
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import ReviewContentModal from "../../components/modals/ReviewContentModal";
import AppNavigation from "../../components/navigation/appNavigation/AppNavigation";
import MainFooter from "../../components/footer/MainFooter";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
} from "../../components/toastify/AllToastifyErrors";
import ContentReadinessModal from "../../components/modals/ContentReadinessModal";
import { reviewContent } from "../../api/reviewContent";
import { extractIndividualQuestionSet } from "../../api/extractIndividualQuestionSet";
import { deleteParticularQuestionSet } from "../../api/deleteParticularQuestionSet";
import { fetchUsersAllQuestions } from "../../api/fetchUsersAllQuestions";
import DeleteQuestionSetModal from "../../components/modals/DeleteQuestionSetModal";

const HistoryPage = () => {
  // Add your existing code here...
  const [isLoading, setIsLoading] = React.useState(false);

  // New states for pagination
  const itemsPerPage = 20; // Number of items to show per page
  const [displayCount, setDisplayCount] = useState(itemsPerPage);

  // Function to handle "Show More" button click
  const showMoreItems = () => {
    setDisplayCount((prevCount) => prevCount + itemsPerPage);
  };

  // State variable to control the open/close state of the modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCanvas = useCanvasDetection();
  const navigate = useNavigate();

  const [usersQuestionsHistory, setUsersQuestionsHistory] = useState(
    JSON.parse(localStorage.getItem("usersQuestionHistory")) || []
  );

  const email = localStorage.getItem("email");
  let title;

  const updateUserQuestionHistory = (newHistory) => {
    setUsersQuestionsHistory(newHistory);
    localStorage.setItem("usersQuestionHistory", JSON.stringify(newHistory));
  };

  useEffect(() => {
    const handleLocalStorageChange = (e) => {
      if (e.key === "usersQuestionHistory") {
        const newHistory = JSON.parse(e.newValue);
        updateUserQuestionHistory(newHistory);
      }
    };

    window.addEventListener("storage", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, []);

  // this is for canvas History issue , sometimes we need to do reload to show history , below useEffect will handle that problem 
  useEffect(() => {
    const init = () => {
      // Your initialization logic goes here
      console.log("Component initialized");

      // For testing purposes, you can simulate an API call
      // Replace this with your actual initialization code
      setTimeout(() => {
        console.log("Data loaded");
      }, 2000);
    };

    if (document.readyState === "complete") {
      init();
    } else {
      document.addEventListener("DOMContentLoaded", init);
    }

    // Cleanup logic (if needed)
    return () => {
      // Your cleanup logic goes here
      console.log("Component cleanup");
    };
  }, []);

  // Add new state variables for doc_id and title
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");

  // Updated handleModalOpen to accept doc_id and title
  const handleModalOpen = (doc_id, title) => {
    setSelectedDocId(doc_id);
    setSelectedTitle(title);
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    // Do any other logic you need before redirecting to "/"
  };

  const handleDeleteParticularQuestionSet = async () => {
    try {
      setIsLoading(true);
      setIsModalOpen(false);
      if (!selectedDocId) return; // Check if doc_id is available

      const response = await deleteParticularQuestionSet(selectedDocId, email);

      const userQuestionsHistory = await fetchUsersAllQuestions(email);

      const updatedHistory = [...userQuestionsHistory.data.doc_list];

      setUsersQuestionsHistory(updatedHistory);

      // Save the updated history to localStorage
      localStorage.setItem(
        "usersQuestionHistory",
        JSON.stringify(updatedHistory)
      );

      commonSuccessToastMessage(
        `The set of questions titled "${selectedTitle}" has been effectively removed.`
      );

      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      commonErrorToastMessage("Error while deleting the questions set");
      console.log("Error while deleting the question set : ", error);
    }
  };

  const handleSingleQuestionSetExtraction = async (doc_id, title) => {
    try {
      setIsLoading(true);

      localStorage.setItem("doc_id", doc_id);
      localStorage.setItem("title", title);

      const response = await extractIndividualQuestionSet(doc_id, title, email);

      const totalQuestionsGenerated =
        response?.data?.document[0]?.questions_data?.questionset?.questionset
          .no_ques;

      localStorage.setItem("totalQuestionsGenerated", totalQuestionsGenerated);
      // Log the API response
      navigate("/question", {
        state: {
          generatedQuestions:
            response.data?.document[0]?.questions_data?.questionset,
          historyPage: "true",
          title: title,
          doc_id: doc_id,
          userAttempts: localStorage.getItem("UserAttempts"),
          totalQuestionsGenerated: totalQuestionsGenerated,
        },
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      commonErrorToastMessage("Error while extracting the questions set.");
      console.log("Error while extracting the questions set : ", error);
    }
  };

  const getFormattedMonth = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", year: "numeric", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  
  // Usage of getFormattedMonth() function
  // const formattedDate = getFormattedMonth(item.created_at);

  return (
    <div className=" ">
      {/* {isCanvas ? null : <AppNavigation />} */}
      <AppNavigation />
      <div
        className={`  flex  justify-center custom-scrollbar overflow-x-hidden overflow-y-auto h-[81vh]`}
      >
        {/* Display doc_id and title of every element in the array with numbering */}
        <div className="m-5">
          <p className="font-bold text-3xl text-[#777E8D] mb-3 text-center double-underline">
            History{" "}
          </p>

          {/* TODO: You can show on the history page the total number of question sets generated by users so far. (Use one of these two styling) */}
          {/* <p className="mb-3 flex justify-end">
            <span className="border  flex  items-center border-[#75fe85] bg-[#c6fecd]  px-3 py-1  text-black  font-semibold rounded   ">
              <span className="pe-2">Total Question Sets : </span>
              <span className="bg-[#68f978]  h-8 w-8 flex items-center justify-center rounded-full">
                {usersQuestionsHistory.length}
              </span>
            </span>
          </p> */}

          {/* <p className="mb-3 flex justify-end">
            <span className="border  flex  items-center border-[#69dbf4] bg-[#cff4fc]  px-3 py-1  text-black  font-semibold rounded   ">
              <span className="pe-2">Total Question Sets : </span>
              <span className="bg-[#86e6fe] border border-r-2 border-t-2 border-l-2 border-[#36d5fd]  h-8 w-8 flex items-center justify-center rounded-full">
                {usersQuestionsHistory.length}
              </span>
            </span>
          </p> */}

          {usersQuestionsHistory
            .filter(
              (item, index) => index < displayCount && item.notes === true
            )
            .map((item, index) => (
              <div key={index} className="border-b py-4  w-[70vw]">
                <div
                  className="flex justify-between  align-middle items-center
                "
                >
                  <Tooltip
                    title={item.title}
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                  >
                    <button
                      onClick={() =>
                        handleSingleQuestionSetExtraction(
                          item.doc_id,
                          item.title
                        )
                      } // Pass the doc_id as a parameter
                      className=" font-semibold text-[#F4833D] hover:underline hover:opacity-80"
                    >
                      {" "}
                      {index + 1}. Title: {truncateText(item.title, 50)}
                    </button>
                  </Tooltip>

                  <p className="">
                    <Tooltip
                      title="Delete"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className="text-red-500 hover:scale-110 hover:transition-all "
                        onClick={() => handleModalOpen(item.doc_id, item.title)}
                      >
                        <i className="mx-4 text-red-500 fa-solid fa-trash hover:opacity-80 "></i>
                        {""}
                      </button>
                    </Tooltip>

                    <Tooltip
                      title="Get Questions"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <button
                        className="hover:scale-110 hover:transition-all"
                        onClick={() =>
                          handleSingleQuestionSetExtraction(
                            item.doc_id,
                            item.title
                          )
                        }
                      >
                        <i className="fa-regular fa-eye text-green-700 hover:opacity-80"></i>
                        {""}
                      </button>
                    </Tooltip>
                  </p>
                </div>

                {/* <p className="mt-5">Doc Id: {item.doc_id}</p> */}
                <div className="flex justify-between mx-auto ">
                  <p className="mt-5  w-auto">
                    <span className="text-[#B0B5C1]">Posted - </span>
                    {/* Convert the timestamp to a date format */}
                    <span>{getFormattedMonth(item.created_at)}</span>
                  </p>
                  <p className="mt-5 font-semibold">|</p>
                  <p className="mt-5">
                    <span className="text-[#B0B5C1]">
                      Number of questions -
                    </span>{" "}
                    <span>
                      {typeof item.no_ques === "object" ? "0" : item.no_ques}
                    </span>
                  </p>
                  <p className="mt-5 font-semibold">|</p>
                  <p className="mt-5">
                    <span className="text-[#B0B5C1]">Notes - </span>
                    {"  "}
                    <span>{item.notes ? "Added" : "Not Added"}</span>
                  </p>
                </div>
              </div>
            ))}

          {/* "Show More" button */}
          {displayCount < usersQuestionsHistory.length && (
            <Tooltip
              title="View more question sets"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                onClick={showMoreItems}
                className="border border-[#7234fd] hover:bg-[#6a28fd] hover:text-white px-2 py-1 text-[#6a20fd]  font-semibold my-4   rounded hover:scale-105 transition duration-300 ease-in-out hover:opacity-80 float-right"
              >
                View More{" "}
                <span>
                  <i class="fa-solid fa-angles-right"></i>
                </span>
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      {/* Render the GenerateQuestionsModal component if the modal is open */}

      {/* Loading overlay */}
      {isLoading && <LoadingOverlay />}

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="youtube-modal-title"
        // Apply the style to the modal container
        className="flex place-content-center items-center fixed"
        // Disable clicking on the backdrop and Escape key
        BackdropProps={{
          style: { pointerEvents: "none" },
        }}
        disableEscapeKeyDown
      >
        <div
          className={`bg-white  shadow-lg text-[#212529] p-6 rounded-lg   animate-fade-slide-down w-[40vw]`}
        >
          <div className="flex  border-b pb-4 ">
            <h2 className="text-2xl text-center font-semibold text-red-400 ">
              {" "}
              <span>
                <i className=" text-red-400  fa-solid fa-triangle-exclamation"></i>
              </span>
              <span className="mx-4">Alert</span>
            </h2>
            {/* <Tooltip
            title="Close"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
              onClick={handleModalClose}
            >
              <i className="fa-solid fa-xmark"></i>{" "}
            </button>
          </Tooltip> */}
          </div>

          <div className="my-4">
            <p className="">
              Are you sure you want to delete the question set titled "
              <span className="text-[#F4833D]  underline">{selectedTitle}</span>
              " ?
            </p>
          </div>

          <div className="flex place-content-end gap-8">
            <Tooltip
              title="Keep this question set"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                className={`rounded mt-4 px-6 py-2 hover:opacity-90 text-[#6c757d] hover:bg-[#6c757d] hover:border-[#6c757d] hover:text-white border border-[#6c757d]  `}
                onClick={handleModalClose}
              >
                No
              </button>
            </Tooltip>

            <Tooltip
              title="Delete this question set"
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 300 }}
            >
              <button
                className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover:bg-[#e06a21] "
                onClick={handleDeleteParticularQuestionSet}
              >
                Yes
              </button>
            </Tooltip>
          </div>
        </div>
      </Modal>

      <MainFooter />
    </div>
  );
};

export default HistoryPage;
