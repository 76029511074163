import React, { useState } from "react";
import SignUpForm from "../../pages/signup/SignupForm";
import { occupations } from "../../data/Signup/occupationArray";
import { signUpSuccessToast } from "../../components/toastify/AllToastifyErrors";
import { validateFormFields } from "../../utils/Validations/signupValidation";
import { useNavigate } from "react-router-dom";
import WebsiteNavigation from "../../components/navigation/websiteNavigation/WebsiteNavigation";
import WebsiteFooter from "../../components/footer/WebsiteFooter";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
// import SignUpForm from "../../pages/signup/SignupForm";

const QuillionzProSignup = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    occupation: occupations[0].value,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    occupation: "",
  });

  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const handleNext = async () => {
    try {
      // Validate First Name and Last Name
      const validationErrors = validateFormFields(userData, setErrors, "");

      if (errors)
        if (
          Object.values(errors).every((errorMessage) => errorMessage === "")
        ) {
          // If there are no errors, proceed with signup
          try {
            // ... (existing code)
          } catch (error) {
            console.log("Error:", error);
          }
        }
    } catch (error) {
      console.log("Error:", error.response.status);
    }
  };
  return (
    <div className="">
      <div>
        <WebsiteNavigation />
      </div>

      <div className=" custom">
        <div className="text-center ">
          <h1 className="text-[32px] text-[#111111] font-bold my-4">
            Sign up now to unlock the Quillionz Pro experience!
          </h1>

          <p className="text-[#666] text-[1.5rem] my-4">
            Create a Quillionz Pro account
          </p>
        </div>

        <div className=" w-[37%] mx-auto">
          <SignUpForm
            userData={userData}
            setUserData={setUserData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="w-[37%] text-justify mx-auto text-[#666]">
          <p className="mb-3 text-[15px] cursor-pointer">
            <input
              className="me-2"
              type="checkbox"
              name=""
              id=""
              checked={isAgreementChecked}
              onChange={() => setIsAgreementChecked(!isAgreementChecked)}
            />
            <span onClick={() => setIsAgreementChecked(!isAgreementChecked)}>
              Yes, I agree that Harbinger may contact me for news, updates,
              marketing and sales purposes. I confirm to the processing of my
              Personal Data as per the terms in the{" "}
            </span>
            <span className="text-[#337ab7] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02] ">
              <Tooltip
                title="Terms of use"
                arrow
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
              >
                <button>
                  <a
                    href="/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    Terms of use.
                  </a>
                  {""}
                </button>
              </Tooltip>
            </span>
          </p>

          <p className="text-[14px]">
            Thank you for choosing Quillionz. If you are residing in India, it
            is likely that you may face some payment issues due to the revised
            online payment rules. This is a temporary concern. Meanwhile, please
            write to us at{" "}
            <span>
              <a
                href="mailto:support@quillionz.com"
                className="text-[#337ab7] transition ease-in-out delay-150 cursor-pointer hover:text-[#f15f02]"
              >
                support@quillionz.com
              </a>
            </span>{" "}
            for making online payment transfer using NEFT/RTGS.
          </p>

          <p className="text-center mt-3">
            {errors.checkbox && (
              <span className="text-red-500 antialiased animate-fade-slide-down text-[13px]">
                {errors.checkbox}
              </span>
            )}
          </p>
        </div>
        <div className="text-center">
          <Tooltip
            title="Next"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="text-[#112a38] text-lg font-bold border-2 border-[#f15f02] px-4 py-2 mt-7 mb-8 text-center transition ease-in-out delay-150   hover:bg-[#EF7920] hover:text-white"
              onClick={handleNext}
            >
              Next
            </button>
          </Tooltip>
        </div>
      </div>

      <WebsiteFooter />
    </div>
  );
};

export default QuillionzProSignup;
