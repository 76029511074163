import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingOverlay from "../overlays/LoadingOverlay";
import { inviteAFriend } from "../../api/inviteAFriend";
import {
  commonErrorToastMessage,
  commonSuccessToastMessage,
} from "../toastify/AllToastifyErrors";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY ='6LfB128pAAAAACbcuiJudHbXr7mbveD8tTRIKQyM';

const InviteAFriendModal = ({ open, onClose, onSubmit }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [recaptchaValue , setRecaptchaValue] = useState('');
  // State variable to hold an array of emails
  const [emails, setEmails] = useState([""]);
  const [message, setMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (index, email) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = email;
    setEmails(updatedEmails);
  };
  useEffect(() => {
    if (open) {
      setEmails([""]);
      setMessage("");
    }
  }, [open]);

  const addNewEmailInput = () => {
    setEmails([...emails, ""]);
  };

  const removeEmailInput = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const Captchalogic =value=>{
    console.log(value);
    setRecaptchaValue(value)
  }
  const handleInviteAFriendButton = async () => {
    try {
      setIsLoading(true);
      const username = localStorage.getItem("username");

      if (emails.length === 0 || emails.every((email) => email.trim() === "")) {
        setIsLoading(false);
        commonErrorToastMessage("Please enter at least one email");
        return;
      }

      const response = await inviteAFriend(username, emails, message , recaptchaValue);

      console.log("Invite a Friend Response : ", response);

      setIsLoading(false);
      commonSuccessToastMessage("Invitation sent successfully!");
      setEmails([""]);
      setMessage("");
      onClose();
    } catch (error) {
      console.error("Error : ", error.response);
      setErrorMessage(error.response.data.success);
      setIsLoading(false);
      setEmails([""]);
      setMessage("");
      commonErrorToastMessage("Oops! Invitation send failed. Try again");
      onClose();
    }
  };

  const handleModalClose = () => {
    setEmails([""]);
    setMessage("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="youtube-modal-title"
      className="flex place-content-center items-center fixed"
      BackdropProps={{
        style: { pointerEvents: "none" },
      }}
      disableEscapeKeyDown
    >
      <div className="bg-white shadow-lg text-[#212529] p-6 rounded-lg animate-fadeSlideDown transition-all ease-in-out duration-500  w-[35vw]">
        <div className="flex place-content-between border-b pb-5">
          <h2 className="text-lg font-semibold ">Invite Your Friends</h2>
          <Tooltip
            title="Close"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="text-[#6c757d] text-lg hover:bg-[#edeeef] px-2 rounded-full "
              onClick={handleModalClose}
            >
              <i className="fa-solid fa-xmark"></i>{" "}
            </button>
          </Tooltip>
        </div>

        {emails.map((email, index) => (
          <div key={index} className="flex border-b py-3 ">
            <p>To: </p>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              className="px-3 rounded-lg w-5/6 outline-none block "
            />
          </div>
        ))}

        <div className="pt-2 h-40 border-b">
          <textarea
            onChange={handleMessageChange}
            value={message}
            className={`w-full h-full resize-none bg-transparent outline-1 custom-scrollbar overflow-none p-2 `}
            placeholder="Add a message (optional)"
          ></textarea>
        </div>
        {errorMessage && (
          <p className="text-red-500 animate-fade-slide-down text-[13px] signup-error-message w-full border border-red-500  p-2  mb-3">
            {errorMessage}
          </p>
        )}
        {
          <div className="flex place-content-center" style={{ paddingTop: '10px' }}>
        <ReCAPTCHA sitekey={SITE_KEY} onChange={Captchalogic}  />
        </div>
      }
        <div className="flex place-content-center">
          <Tooltip
            title="Invite Your Friend"
            arrow
            placement="top"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
          >
            <button
              className="mt-4 px-6 py-2 bg-[#f4833d] text-white rounded hover-bg-[#e06a21] "
              onClick={handleInviteAFriendButton}
            >
              Invite
            </button>
          </Tooltip>
        </div>
        {isLoading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export default InviteAFriendModal;
