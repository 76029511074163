import React, { useState , useEffect} from "react";
import "../../../assets/styles/Common.css";
import ReviewContentHelpModal from "../modals/ReviewContentHelpModal";
import Checkbox from "@mui/material/Checkbox";
import WebsiteNavigation from "../navigation/websiteNavigation/WebsiteNavigation";
import { useNavigate } from "react-router-dom";
import ReviewContent from "../../pages/ReviewContent/ReviewContent";
import useCanvasDetection from "../../hooks/useCanvasDetection";
import Question from "../../pages/Question/Question";
const StepProgress = ({
  activeStep,
  pronounSuggestion,
  complexSentence,
  subjectiveSentence,
  incompleteSentence,
  mcqQuestion,
  shortAnswerQuestion,
  descriptiveQuestion,
  recallQuestion,
  whQuestions,
  setIsPronounReplacementClicked,
  isPronounReplacementClicked,
  setHideInterpretiveQuestions,
  isLengthySentenceClicked,
  setIsLengthySentenceClicked,
  isSubjectiveSentenceClicked,
  setIsSubjectiveSentenceClicked,
  isIncompleteSentenceClicked,
  setIsIncompleteSentenceClicked,
  isShortAnswerClicked,
  setIsShortAnswerClicked,
  isInterpretiveQuestionsClicked,
  setIsInterpretiveQuestionsClicked,
  isNotesClicked,
  setIsNotesClicked,
  isMultipleChoiceClicked,
  setIsMultipleChoiceClicked,
  isOnlyShortQAClicked,
  setIsOnlyShortQAClicked,
  isOnlyRecallQAClicked,
  setIsOnlyRecallQAClicked,
}) => {
  // const steps = ["Submit Content", "Choose Keywords", "Review Content", "Done"];
  const steps = ["Submit Content", "Choose Keywords", "Done"];

  const [currentStep, setCurrentStep] = useState(activeStep);

  console.log("currentStep :",currentStep);
  const [complete, setComplete] = useState(false);

  // State variable to track whether the stepper is closed or not
  const [closed, setClosed] = useState(false);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      setComplete(true);
      setCurrentStep(steps.length);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Function to handle the "close" button click
  const handleClose = () => {
    setClosed(true);
  };

  // Function to handle the "reopen" button click
  const handleReopen = () => {
    setClosed(false);
  };

  // Hide background color of pronoun suggestion on click
  const handlePronounClick = () => {
    setIsPronounReplacementClicked(!isPronounReplacementClicked);
  };

  // Hide background color of lengthy sentences on click
  const handleLengthSentenceClicked = () => {
    setIsLengthySentenceClicked(!isLengthySentenceClicked);
  };

  // Hide background color of subjective sentences on click
  const handleSubjectiveSentenceClicked = () => {
    setIsSubjectiveSentenceClicked(!isSubjectiveSentenceClicked);
  };

  // Hide and unhide Short Questions
  const handleOnlyShortQuestionsClicked = () => {
    setIsOnlyShortQAClicked(!isOnlyShortQAClicked);
  };

  // Functions to hide and unhide Recall Questions
  const handleOnlyRecallQuestionsClicked = () => {
    setIsOnlyRecallQAClicked(!isOnlyRecallQAClicked);
  };

  // Hide background color of subjective sentences on click
  const handleIncompleteSentenceClicked = () => {
    setIsIncompleteSentenceClicked(!isIncompleteSentenceClicked);
  };

  // Hide wh-question on clicking 'Wh Questions' button
  const handleWhQuestionHide = () => {
    setIsShortAnswerClicked(!isShortAnswerClicked);
  };

  // Hide Interpretive Question on clicking 'Interpretive' button
  const handleInterpretiveQuestionsHide = () => {
    setIsInterpretiveQuestionsClicked(!isInterpretiveQuestionsClicked);
  };

  // Hide Notes on clicking 'Notes' button
  const handleNoteHide = () => {
    setIsNotesClicked(!isNotesClicked);
  };

  // Hide MCQs on clicking 'Multiple Choice' button
  const handleMultipleChoiceHide = () => {
    setIsMultipleChoiceClicked(!isMultipleChoiceClicked);
  };

  // Checkbox check
  const [checkboxesChecked, setCheckboxesChecked] = useState({
    shortAnswer: true,
    mcqQuestion: true,
    recallQuestion: true,
    whQuestions: true,
    interpretiveQuestions: true,
    notes: true,
    // Add other checkbox IDs here
  });

  // Function to handle checkbox click and update checked state
  const handleCheckboxClick = (checkboxId) => {
    setCheckboxesChecked((prevState) => ({
      ...prevState,
      [checkboxId]: !prevState[checkboxId],
    }));
  };

  // let [checkedQuestionsArray , setCheckedQuestionsArray] = useState();

  localStorage.setItem("hideStepProgress", closed);

  // useEffect(() => {
  //   setCheckedQuestionsArray(
  //     Object.entries(checkboxesChecked)
  //       .filter(([key, value]) => value)
  //       .map(([key]) => key)
  //   );
  // }, [checkboxesChecked]);

  const isCanvas = useCanvasDetection();
  return (
    <>
      {!isCanvas && (
        <div
          className={`bg-[#042739] shadow-md stepper-container h-full ${
            closed ? "w-auto " : "w-[230px]"
          } overflow`}
        >
          {closed ? (
            <button className="text-white">
              <p
                className="mb-7 border-b-[1px] border-b-[#2d4552] pb-2 text-xl text-[#818181] "
                onClick={handleReopen}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </p>
              <div className="stepper flex flex-col">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`step ${
                      index === activeStep - 1 ? "active" : ""
                    } ${index < activeStep - 1 ? "complete" : ""} flex`}
                  >
                    <div className="step-content flex items-center">
                      {index < activeStep - 1 ? (
                        <div className="step-circle bg-[#d5d5d8] items-center flex place-content-center rounded-full border">
                          <span>{index + 1}</span>
                        </div>
                      ) : (
                        <div className="step-circle  bg-[#d5d5d8] items-center flex place-content-center rounded-full border">
                          <span>{index + 1}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </button>
          ) : (
            <>
              <div className="text-white  border-b-[1px] border-b-[#2d4552]  pb-1">
                <button className="ml-[-3px]" onClick={handleClose}>
                  <span className="text-xl text-[#818181]">
                    <i className=" fa-solid fa-chevron-right"></i>
                  </span>
                  {""}
                </button>
              </div>
              <div className="mt-4">
                <div className="stepper flex flex-col ">
                  {steps.map((step, index) => (
                    <div
                      key={index}
                      className={`step ${
                        index === activeStep - 1 ? "active" : ""
                      } ${index < activeStep - 1 ? "complete" : ""} flex`}
                    >
                      <div className="step-content flex items-center">
                        {index < activeStep - 1 ? (
                          <>
                            <div className="step-circle bg-[#d5d5d8]  ml-[-33px] me-6 items-center flex place-content-center rounded-full border">
                              <span>{index + 1}</span>
                            </div>

                            <div className="step-label mx-1">{step} </div>
                          </>
                        ) : (
                          <>
                            <div className="step-circle  bg-[#d5d5d8]  ml-[-33px] me-6 items-center flex place-content-center rounded-full border">
                              <span>{index + 1}</span>
                            </div>

                            <div className="step-label mx-1">
                              {step}

                              {/* {activeStep - 1 === index ? (
                                <button>
                                  <span className="ms-4 ">
                                    <i className="fa-solid fa-circle-info"></i>
                                  </span>
                                  {""}
                                </button>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </>
                        )}
                      </div>
                      {activeStep - 1 === index ? (
                        currentStep === 1 ? (
                          <button>
                            <span className=""></span>
                            {""}
                          </button>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {activeStep - 1 === index ? (
                        currentStep === 2 ? (
                          <button>
                            <span className=""></span>
                            {""}
                          </button>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {/* TODO: At the moment, we are setting aside all the items listed in the Review Content tab to address them later, following the site's launch.. */}
                      {/* remove this comment to get review screen steprogress and reset the value of next curly bracket to 4 again */}
                      {
                      //   activeStep - 1 === index ? (
                      //   currentStep === 3 ? (
                      //     <div className="mx-3 w-full">
                      //       {/* Lengthy Sentences */}
                      //       <button
                      //         className="mt-2"
                      //         onClick={handleLengthSentenceClicked}
                      //       >
                      //         <p id="idComplex " className="curated-sentences">
                      //           <span
                      //             id="RC_complex"
                      //             className={`subtStep complex ${
                      //               isLengthySentenceClicked
                      //                 ? "bg-[#feefb8]"
                      //                 : ""
                      //             }  font-weight-bold flex align-middle justify-center place-content-center me-1 text-sm`}
                      //           >
                      //             {complexSentence}
                      //           </span>
                      //           <span className="pb-1 mx-2 text-sm text-white">
                      //             Lengthy sentences
                      //           </span>
                      //         </p>
                      //       </button>
                      //       {/* Subjective Sentences */}
                      //       <button
                      //         className="mt-4 "
                      //         onClick={handleSubjectiveSentenceClicked}
                      //       >
                      //         <p id="idComplex" className="curated-sentences">
                      //           <span
                      //             id="RC_complex"
                      //             className={`subtStep subjective ${
                      //               isSubjectiveSentenceClicked
                      //                 ? "bg-[#a4fcf9]"
                      //                 : ""
                      //             }  font-weight-bold flex align-middle justify-center place-content-center me-1 text-sm`}
                      //           >
                      //             {subjectiveSentence}
                      //           </span>
                      //           <span className="pb-1 mx-2 text-sm text-white">
                      //             Subjective sentences
                      //           </span>
                      //         </p>
                      //       </button>
                      //       {/* Incomplete Sentence */}
                      //       <button
                      //         className="mt-4 "
                      //         onClick={handleIncompleteSentenceClicked}
                      //       >
                      //         <p id="" className="curated-sentences">
                      //           <span
                      //             id="RC_complex"
                      //             className={`subtStep incomplete ${
                      //               isIncompleteSentenceClicked
                      //                 ? "bg-[#fcd4f9]"
                      //                 : ""
                      //             }  font-weight-bold flex align-middle justify-center items-center  place-content-center me-1 text-sm`}
                      //           >
                      //             {incompleteSentence}
                      //           </span>
                      //           <span className="pb-1 mx-2 text-sm text-white">
                      //             Incomplete sentences
                      //           </span>
                      //         </p>
                      //       </button>
                      //       {/* Pronoun suggestion */}
                      //       <button
                      //         className="mt-4 pb-4"
                      //         onClick={handlePronounClick}
                      //       >
                      //         <p id="" className="curated-sentences">
                      //           <span
                      //             id="RC_complex"
                      //             className={`subtStep pronoun ${
                      //               isPronounReplacementClicked
                      //                 ? "bg-[#fbcfc2]"
                      //                 : ""
                      //             }  text-sm`}
                      //           >
                      //             {pronounSuggestion}
                      //           </span>
                      //           <span className="pb-1 mx-2 text-sm text-white">
                      //             Pronoun replacement
                      //           </span>
                      //         </p>
                      //       </button>
                      //       <button
                      //         className="mt-3"
                      //         onClick={() => setIsModalOpen(true)}
                      //       >
                      //         {" "}
                      //         <span className="subtStep subtStep help font-weight-bold flex align-middle justify-center place-content-center me-1 text-sm">
                      //           ?
                      //         </span>
                      //         <span className="mx-2 text-sm text-white">
                      //           Help
                      //         </span>
                      //       </button>
                      //       {/* Render the ReviewContentHelpModal */}
                      //       {isModalOpen && (
                      //         <ReviewContentHelpModal
                      //           isOpen={isModalOpen}
                      //           onClose={() => setIsModalOpen(false)}
                      //         />
                      //       )}
                      //     </div>
                      //   ) : (
                      //     ""
                      //   )
                      // ) : (
                      //   ""
                      // )
                    }
                      {activeStep - 1 === index ? (
                        currentStep === 3 ? (
                          <div className=" w-full text-[13px]">
                            <div className="mt-2">
                              <p className="bg-[#010c10] text-[#a4d3fd] px-3 font-medium">
                                Free Questions
                              </p>
                              <div className=" flex flex-col mx-4 mt-1">
                                {/* Short Answers */}
                                {/* <p className=" pt-2">
                                  <button
                                    className="  flex justify-center align-middle"
                                    onClick={() => {
                                      handleCheckboxClick("shortAnswer");
                                      handleOnlyShortQuestionsClicked();
                                    }}
                                  >
                                    <span className="pe-1">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={checkboxesChecked.shortAnswer}
                                      />
                                    </span>
                                    <p className="text-sm  mt-[-2px]">
                                      <span className="text-[#ccc] ">
                                        Short Answer
                                      </span>{" "}
                                      <span className="text-[#f4833d] ps-1">
                                        ({shortAnswerQuestion})
                                      </span>
                                    </p>
                                  </button>
                                </p> */}

                                <p className="pt-2 ">
                                  <button
                                    className=" flex align-middle "
                                    onClick={() => {
                                      handleCheckboxClick("mcqQuestion");
                                      handleMultipleChoiceHide();
                                    }}
                                  >
                                    <span className="pe-1">
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={checkboxesChecked.mcqQuestion}
                                      />
                                    </span>
                                    <p className="text-sm mt-[-2px]">
                                      <span className="text-[#ccc]">
                                        Multiple Choice
                                      </span>{" "}
                                      <span className="text-[#f4833d] ps-1">
                                        ({mcqQuestion})
                                      </span>
                                    </p>
                                  </button>
                                </p>

                                <p className="pt-2 ">
                                  <button
                                    className=" flex align-middle "
                                    onClick={() => {
                                      handleCheckboxClick("recallQuestion");
                                      handleOnlyRecallQuestionsClicked();
                                    }}
                                  >
                                    <span className="pe-1">
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={
                                          checkboxesChecked.recallQuestion
                                        }
                                      />
                                    </span>
                                    <p className="text-sm mt-[-2px]">
                                      <span className="text-[#ccc]">
                                        Recall
                                      </span>{" "}
                                      <span className="text-[#f4833d] ps-1">
                                        ({recallQuestion})
                                      </span>
                                    </p>
                                  </button>
                                </p>
                              </div>
                            </div>

                            <div className="mt-2">
                              <p className="bg-[#010c10] text-[#f48339] px-3 font-medium">
                                Premium Features
                              </p>
                              <div className=" flex flex-col mx-4 mt-1">
                                <p className=" pt-2">
                                  <button
                                    className="flex align-middle"
                                    onClick={() => {
                                      handleCheckboxClick("whQuestions");
                                      handleWhQuestionHide();
                                    }}
                                  >
                                    <span className="pe-1">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={checkboxesChecked.whQuestions}
                                      />
                                    </span>
                                    <p className="text-sm mt-[-2px]">
                                      <span className="text-[#ccc]">
                                        Wh Questions
                                      </span>{" "}
                                      <span className="text-[#f4833d] ps-1">
                                        ({whQuestions})
                                      </span>
                                    </p>
                                  </button>
                                </p>

                                <p className="pt-2 ">
                                  <button
                                    className=" flex align-middle"
                                    onClick={() => {
                                      handleCheckboxClick(
                                        "interpretiveQuestions"
                                      );
                                      handleInterpretiveQuestionsHide();
                                    }}
                                  >
                                    <span className="pe-1">
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={
                                          checkboxesChecked.interpretiveQuestions
                                        }
                                      />
                                    </span>
                                    <p className="text-sm mt-[-2px] ">
                                      <span className="text-[#ccc]">
                                        Interpretive
                                      </span>{" "}
                                      <span className="text-[#f4833d] ps-1">
                                        ({descriptiveQuestion})
                                      </span>
                                    </p>
                                  </button>
                                </p>

                                <p className="pt-2 ">
                                  <button
                                    className=" flex align-middle"
                                    onClick={() => {
                                      handleCheckboxClick("notes");
                                      handleNoteHide();
                                    }}
                                  >
                                    <span className="pe-1">
                                      <input
                                        type="checkbox"
                                        name="notes"
                                        id=""
                                        className=" cursor-pointer"
                                        checked={checkboxesChecked.notes}
                                      />
                                    </span>
                                    <p className="text-sm mt-[-2px]">
                                      <span className="text-[#ccc]">Notes</span>{" "}
                                    </p>
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>

  );
};

export default StepProgress;
